import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import 'swiper/css';

import styles from '../components/TabGroup.module.css'

function TabGroup({tabs, tabIndex, setTabIndex}) {
  const handleChange = (e, value) => {
    //console.log(value);
    setTabIndex(value);
  }

  return (
      <div className={styles.container}>
        <Tabs 
          onChange={handleChange} 
          value={tabIndex} 
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          >
            {tabs?.map((tab, index) => (
                <Tab
                  key={index}
                  label={<span className={styles.tabText}>{tab.label}</span>}
                  value={index}
                  sx={{ minWidth: "fit-content", flex: 1}}
                 />
              ))
            }
        </Tabs>

        <div className={styles.tabContentContainer}>
          {tabs?.map((tab, index) => (
              tabIndex === index && (
                <div
                  key={tab.label}
                  className={styles.tabContent}
                >
                  {tab.content}
                </div>
                )
            ))
          }
        </div>
      </div>
  )
}

export default TabGroup
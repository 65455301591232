import {useState, useEffect, useContext} from 'react'
import { useParams } from 'react-router-dom';
import { AppContext } from "../appInfo";

import Layout from "../components/Layout"
import Header from "../components/Header"
import RestartQuestChip from '../components/units/RestartQuestChip';

import ChallengeCard from '../components/challenge/ChallengeCard';

import {StudentQuestChallengesAPI} from "../api/StudentQuestChallengesAPI"

function PantallaMision() {
  const { course, qid } = useParams();
  const [cards, setCards] = useState(null)
  const { state } = useContext(AppContext)
  const { userview, isStaff } = state


  useEffect( () =>{
    if(!userview) {
      return
    }
    StudentQuestChallengesAPI.getAll(userview, course, qid).then((data) => {
      setCards(data)

    });
    return () => {
      //StudentQuestChallengesAPI.abort() // cancel request
    }
  }, [course, qid, userview])

  return (
      <Layout 
        Header={
        <Header
          title={"Desafíos"}
          linkType={"back"}
          btn1={isStaff? <RestartQuestChip qid={qid} /> : null}
        />
      }
      >
      {Array.isArray(cards)
        ?
        cards.map( card => (
          <ChallengeCard props={card} key={card.id}/>
        ))
        : "CARGANDO..."
      }
      </Layout>
  )
}

export default PantallaMision
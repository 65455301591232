import styles from "./MiniCardNotes.module.css";
import { useState } from "react";
import IconDic from "../utils/IconDic";
import toast from 'react-hot-toast';

function MiniCardNotes({ sx, data }) {
  const [buttonActivated, setButtonActivated] = useState(false);

  function handleButton(event) {
    event.preventDefault(); // Prevenir la recarga de la página
    setButtonActivated(true);

    if (data.is_disabled) {
      toast.error("El apunte estará disponible cuando complete el objetivo de esta unidad");
    }
    else {
      window.open(data.url, '_blank');
    }
  }

  return (
    <div className={styles.container} style={sx}>
      <div className={styles.imgContainer}>
        <IconDic sx={sx} type={data.type} />
      </div>
      <div className={styles.dataText}>{data.name}</div>
      <button
        type="button"
        onClick={handleButton}
        className={`${styles.dataButton} ${buttonActivated ? styles.buttonActivated : ""
          }`}
      >
        Descargar
      </button>
    </div>
  );
}

export default MiniCardNotes;

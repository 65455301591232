import classNames from 'classnames'

import styles from "./IconTextButton.module.css";


function IconTextButton({icon, text, disabled, onClick}) {


  const containerClass = classNames({
    [styles.container]: true,
    [styles.cursor]: true,
    [styles.disabled]: disabled===true,
    
  }); 

  return (
    <div className={containerClass}
      onClick={onClick}
    >
      {icon}
      <h2>{text}</h2>
    </div>
  )
}

export default IconTextButton
import styles from './QuestBillboard.module.css'


function QuestBillboard({message}) {

  return (
      <div className={styles.container}>
        <div className={styles.text}>{message}</div>
          <div className={styles.arrowContainer}>
              <div className={styles.arrow}>
              </div>
          </div>
      </div>
  )
}

export default QuestBillboard
import {useState} from 'react'

import Rating from '@mui/material/Rating';
import Box from '@mui/material/Box';
import StarIcon from '@mui/icons-material/Star';

function FeedbackRating({value, disabled, onChange}) {
    const [hover, setHover] = useState(-1);
  
    const labels = {
      0: 'Sin calificar',
      1: 'Muy mala',
      2: 'Mala',
      3: 'Neutral',
      4: 'Buena',
      5: 'Muy buena',
    };
  
    //const margin = disabled? '10px': '40px'
    const margin = '0px';
  
    function getLabelText(value) {
      return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
    }
  
    return (
      <Box
        sx={{
          width: "100%",
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          margin: margin,
        }}
      >
        <Rating
          name="hover-feedback"
          value={value}
          precision={1}
          max={5}
          disabled={disabled? disabled: false}
          size="large"
          sx={{fontSize:"48px"}}
          getLabelText={getLabelText}
          onChange={(e, newValue) => {onChange(newValue)}}
          onChangeActive={(e, newHover) => {
            setHover(newHover);
          }}
          emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
        />
        {value !== null && (
          <Box sx={{ ml: 2 }}>{labels[hover !== -1 ? hover : value]}</Box>
        )}
      </Box>
    );
}

export default FeedbackRating
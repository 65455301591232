import {useState, useEffect, useContext} from 'react'
import { useParams } from "react-router-dom"
import { AppContext } from "../appInfo";

import Layout from "../components/Layout"
import Header from "../components/Header"
import NavBar from "../components/NavBar"

import FeedbackCard from "../components/feedback/FeedbackCard"

import {FeedbackListAPI} from "../api/FeedbackListAPI"

function PantallaDevoluciones() {
  const { course } = useParams();
  const [feedbacks, setFeedbacks] = useState("SIN DEVOLUCIONES...")  
  const { state } = useContext(AppContext)
  const { username } = state

  useEffect( () =>{
    if(!username) {
      return
    }
    FeedbackListAPI.getAll(username, course).then((feedbacks) => {
      //console.log(feedbacks);
      if(feedbacks.length > 0) {
        setFeedbacks(feedbacks)
      }
      
    });
    return () => {
      //FeedbackListAPI.abort() // cancel request
    }
  }, [course, username])

  return (
      <Layout 
        Header={<Header title={"Feedback"}/>}
      > 
        {Array.isArray(feedbacks)
        ?
        feedbacks.map( feedback => (
          <FeedbackCard
              key={feedback.fid}
              fid={feedback.fid}
              title={feedback.title}
              completed={feedback.completed}
              points={feedback.points}
              rating={feedback.rating_score}
              rating_text={feedback.rating_text}
              user={feedback.user}
              date={feedback.date}
              userview={feedback.userview}
            />
          ))
          : feedbacks
        }
      </Layout>
  )
}

export default PantallaDevoluciones
import TextBox from '../TextBox'

function ChallengeStatement({cid, challengeCode, statement}) {
  return (
    <TextBox>
      {[`Desafío #${challengeCode}:`,statement]}
    </TextBox>
  )
}

export default ChallengeStatement
import { useParams } from "react-router-dom"

import TextBox from '../TextBox'

function ChallengeHelp({cid}) {
  const { course } = useParams();

  return (
    <TextBox>
    {
      [
        `Ingresar al canal de discord del curso "${course}"`,
        "¡Consultales a los profes y tus compañeros tus dudas que estarán contentos de ayudarte!"
      ]
    }
  </TextBox>
  )
}

export default ChallengeHelp
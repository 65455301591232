import styles from './QuestFinishLine.module.css'

import SportsScoreIcon from '@mui/icons-material/SportsScore';

function QuestFinishLine() {

  return (
      <div className={styles.container}>
        <SportsScoreIcon className={styles.flags} fontSize="medium" color={"success"} />
        <div className={styles.line} />
        <SportsScoreIcon className={styles.flags} fontSize="medium" color={"success"}/>
      </div>
  )
}

export default QuestFinishLine
import { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom"
import { AppContext } from "../appInfo";

import Layout from "../components/Layout"
import Header from "../components/Header"

import MiniCard from "../components/MiniCard"

import {CourseAPI} from "../api/CourseAPI"

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DangerousIcon from '@mui/icons-material/Dangerous';

function PantallaCalendario() {
  const { course } = useParams();
  const [classes, setClasses] = useState()

  const { state } = useContext(AppContext)
  const { username } = state

  useEffect( () =>{
    if(!username) {
      return
    }
    CourseAPI.calendar(username, course).then((data) => {
      setClasses(data)
    });
    return () => {
      //CourseAPI.abort() // cancel request
    }
  }, [username, course])

  return (
      <Layout 
        Header={<Header title={"Calendario"}/>}
      >
        {Array.isArray(classes)
        ?
          classes.map( (data, index) => (
            <MiniCard
              key={index}
              sx={{ width: "clamp(350px, 100%, 400px)" }}
              icon={data.class_number? <CalendarMonthIcon sx={{fontSize:"40px"}} />: <DangerousIcon sx={{fontSize:"40px"}}/>}
              value={data.class_datetime}
              text={data.class_number? `Clase nº ${data.class_number}`:"SIN CLASES"}
            />
          ))
          : "...CARGANDO"
        }
      </Layout>
  )
}

export default PantallaCalendario
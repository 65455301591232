import {useState, useContext} from 'react'
import { useParams } from 'react-router-dom';
import { AppContext } from "../../appInfo";
import classNames from 'classnames'

import styles from './ChallengeTest.module.css'

import Congrats from "../Congrats"
import ChallengeTestCodeScript from "./tests/ChallengeTestCodeScript"

import {StudentChallengeTestAPI} from "../../api/StudentChallengeTestAPI"

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import toast from 'react-hot-toast';


function ChallengeDWTest({cid, isRepository, setScore, setBonus, setCompleted}) {
  const [scoreText, setScoreText] = useState()
  const [errorMessage, setErrorMessage] = useState()
  const [errorDetail, setErrorDetail] = useState()
  const [tests, setTests] = useState()
  const [resultHide, setResultHide] = useState(true);
  const [congrats, setCongrats] = useState(null)

  const [evaluated, setEvaluated] = useState(false)
  const [previewed, setPreviewed] = useState(false)

  const [solution, setSolution] = useState("")
  const [previewUrl, setPreviewUrl] = useState("")

  const { course } = useParams();
  const { state } = useContext(AppContext)
  const { username } = state

  const submitSolution = (solution) => {
    setResultHide(true);
    toast.success("Enviando solución....", { icon: '🌠' });
    StudentChallengeTestAPI.test(username, course, cid, solution).then((data) => {

      setScoreText(`Puntaje: ${data["score"]}`);
      setResultHide(false);
      setEvaluated(false)
      setPreviewed(false)

      if(data["coderun"]) {
        if(data["tests"]) {
          setTests(data["tests"]);
        }
        else {
          setTests([]);
        }
        setScore(data["score"])
        setBonus(data["bonus"])
        if(data["score"] === 100){
          setCompleted(true)
        }

        if(data["experience"]) {
          setCongrats(
            <Congrats
              message="¡Felicitaciones!" 
              points={data["experience"]["score"]}
              bonusPoints={data["experience"]["bonus"]}
              extrapoints={data["experience"]["quest_bonus"]}
            />
          );
          
        }

        setErrorMessage("");
        setErrorDetail("");
      } 
      else {
        setTests([]);
        setErrorMessage(data["message"]);
        setErrorDetail(data["description"]);
      }
      
    });
  }

  const evaluateSolution = (solution) => {
    setResultHide(true);
    setPreviewed(false)
    toast.success("Enviando solución....", { icon: '🌠' });
    StudentChallengeTestAPI.evaluate(username, course, cid, solution).then((data) => {
      setScoreText("");
      setResultHide(false);

      if(data["coderun"]) {
        // nothing, falta la evaluacion
        setPreviewUrl(data["preview_url"])
        setEvaluated(true)
      } 
      else {
        setTests([]);
        setErrorMessage(data["message"]);
        setErrorDetail(data["description"]);
      }
      
    });
  }

  const handleFormEvaluate = (e) => {
    e.preventDefault();
    if(e.target.url.value === "") {
      toast.error("Debe especificar el link de su repositorio");
      return;
    }
    setSolution(e.target.url.value);
    evaluateSolution(e.target.url.value);
  };

  const handlePreview = () => {
    setPreviewed(true)
    window.open(previewUrl, '_blank');
  };

  const handleFormSubmit = () => {
    submitSolution(solution);
  };

  // Si los suelta en el document (por error) no hacer nada
  document.addEventListener('dragover',function(event){event.preventDefault();},false);
  document.addEventListener('drop',function(event){event.preventDefault();});

  const resultSessionClass = classNames({
    [styles.section]: true,
    [styles.hide]: resultHide,
  }); 

  return (
    <>
    <div className={styles.section}>
        <>
        <h3>Indicanos el link de tu repositorio y aprieta el botón</h3>
        <form onSubmit={handleFormEvaluate} style={{width: "100%"}}>
          <TextField
            id="url"
            type={"url"}
            placeholder={`Link de su repositorio`}
            fullWidth
            margin="normal"
          />
          <Button 
            variant="contained"
            color="success"
            size="large"
            type="submit"
          >
            Enviar repositorio
          </Button>
        </form>
        { evaluated && 
          <>
          <h3>Presiona el botón de "vista previa" y evalua si el resultado cubre tus expectativas y las del desafío</h3>
          <Button variant="contained" color="secondary" size="large" type="submit" onClick={() => handlePreview()} > VISTA PREVIA </Button> 
          </>
        }
        { evaluated && previewed && 
          <>
          <h3>Si estás satisfecho con el resultado, presiona ENVIAR</h3>
          <Button variant="contained" color="success" size="large" type="submit" onClick={() => handleFormSubmit()} > ENVIAR RESULTADO </Button>
          </>
        }
        </>
    </div>
    <section className={`${resultSessionClass} swiper-no-swiping`}>
      <h3>{scoreText}</h3>
      <div>{errorMessage}</div>
      <div style={{textAlign: "start"}}><pre>{errorDetail}</pre></div>
    </section>
    
    <section className={`${resultSessionClass} ${styles.testCardSection} ${"swiper-no-swiping"}`} >
      {congrats}
      <ChallengeTestCodeScript>
        {tests}
      </ChallengeTestCodeScript>
    </section>
    </>
  )
}

export default ChallengeDWTest
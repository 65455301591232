import { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { AppContext } from "../appInfo";

import Layout from "../components/Layout"
import Header from "../components/Header"
import RankingCard from "../components/progress/RankingCard"

import {CourseMetricsAPI} from "../api/CourseMetricsAPI"


function PantallaRanking() {
  const { course } = useParams();
  const { state } = useContext(AppContext)
  const { username } = state

  const [ranking, setRanking] = useState([])

  useEffect( () =>{
    if(!username) {
      return
    }
    CourseMetricsAPI.ranking(username, course).then((data) => {
      setRanking(data)
      
    });
    return () => {
      //StudentProgressAPI.abort() // cancel request
    }
  }, [username, course])

  const flexItem = {
    width: "100%",
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    flexDirection: "column",
    gap: "15px",
  };


  return (
      <Layout 
        Header={<Header title={"Ranking"}/>}
      >

      {/* <div style={flexItem}> */}
        {Array.isArray(ranking)
          ?
          ranking.map( (row, index) => (
              <RankingCard props={row} isStaff={false} key={index} />
            ))
            : null
        }
      {/* </div> */}
      
      </Layout>
  )
}

export default PantallaRanking
import { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { AppContext } from "../appInfo";
import styles from "./PantallaApuntes.module.css";

import Layout from "../components/Layout";
import Header from "../components/Header";

import { RecursosAPI } from "../api/RecursosAPI"

import MiniCardNotes from "../components/MiniCardNotes.js";

function PantallaApuntes() {
  const { course, uidx } = useParams();
  const { state } = useContext(AppContext);
  const [data, setData] = useState();
  const { username } = state;

  useEffect(() => {
    if (!username || !course) {
      return;
    }

    // FER: Dejé la API que recolecta todos los apuntes para
    // esta pantalla
    // No es necesario que uses TabSwiper, en realidad está de más
    // Podes usar como ejemplo "PantallaUnidades", vas a ver que podrías
    // listar directamente todos los componentes en este jsx
    // Copiar el código que tenes en FileTypes y listarlos acá
    RecursosAPI.getAll(username, course, uidx)
      .then((responseData) => {
        setData(responseData);
      })
      .catch((error) => {
        console.log("Error al obtener los recursos:", error);
      });

    return () => {
      setData({}); 
    };
  }, [course, username, uidx]);

  return (
    <Layout Header={<Header  title={"Apuntes"} linkType={"back"} />}>
      {Array.isArray(data) ? (
        <>
          <div key={data.id} className={styles.container}>
          {data.map((data) => (
            <MiniCardNotes data={data} className={styles.swiperSlide} key={data.id}/>
          ))}
          </div>
        </>
      ) : (
        "CARGANDO..."
      )}
    </Layout>
  );
}

export default PantallaApuntes;

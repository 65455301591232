import {useState, useEffect, useContext} from 'react'
import { useParams, useNavigate } from "react-router-dom";
import { AppContext } from "../../appInfo";

import Chip from '@mui/material/Chip';
import DoneIcon from '@mui/icons-material/Done';

import toast from 'react-hot-toast';

import { FeedbackRequestAPI } from "../../api/FeedbackRequestAPI"

function FeedbackChip({props, status, setStatus}) {
  // console.log(props)
  const { course } = useParams();
  const { state } = useContext(AppContext)
  const { username } = state
  const navigate = useNavigate();

  const [component, setComponent] = useState(<></>)

  useEffect( () =>{
    let callback = (e) => {
      e.preventDefault();
      toast.error("¡Aún no alcanzaste la meta!");
    }
    let label = props.status_str;
    let backgroundColor = "var(--gray)";
    let color = "black";
    if(status === 2) {
      // Se puede pedir la devolucion
      backgroundColor = "var(--blue)"
      color = "#fff";
      callback = (e) => {
        e.preventDefault();
        toast.success("Enviando solicitud..." , { icon: '🌠' });
        FeedbackRequestAPI.getAll(username, course, props.fid).then((feedback) => {
          toast.success("¡Devolución solicitada!");
          setStatus(3)
        }).catch( error => {
          console.log(error);
          if (error.response) {
            // La solicitud fue realizada y el servidor respondió
            // con un código de estado que no está en el rango 2xx
            if (error.response.status === 400) {
              toast.error("Aún tiene devoluciones sin calificar. Complete sus devoluciones para poder solicitar una nueva.");
            } else {
              toast.error("Ocurrio un error al solicitar la devolución");
            }
          } else {
            toast.error("Ocurrio un error al solicitar la devolución");
          }
        });
        return () => {
          //StudenUnitAPI.abort() // cancel request
        }
        
      }
    }
    else if(status === 3) {
      // Devolución pendiente
      backgroundColor = "var(--purple)"
      color = "#fff";
      label = "Devolución Pendiente";
      callback = (e) => {
        e.preventDefault();
        toast.success("El profe ya está al tanto de tu trabajo");
      }
    }
    else if(status === 4) {
      // Devolución recibida
      backgroundColor = "var(--gold)"
      color = "black";
      callback = (e) => {
        e.preventDefault();
        navigate(`/cursos/${course}/devoluciones/alumno/${props.fid}`)
      }
    }
    else if(status === 5) {
      // Devolución completa
      backgroundColor = ""
      color = "black";
      callback = (e) => {
        e.preventDefault();
        navigate(`/cursos/${course}/devoluciones/alumno/${props.fid}`)
      }
    }
    else if(status === 6) {
      // Devolución vencida
      backgroundColor = "var(--gray)"
      color = "black";
      callback = (e) => {
        e.preventDefault();
        toast.error("No puede solicitar una devolución");
      }
    }

    setComponent(
      <Chip 
        label={label}
        sx={{
          fontSize:"1rem",
          color: color,
          backgroundColor:backgroundColor,
          paddingTop:"15px",
          paddingBottom:"15px",
        }}
        />
    )

  }, [props, status, course, username, navigate])


  return (
   <>{component}</>
  )
}

export default FeedbackChip
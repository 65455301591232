import { createContext } from "react";

export const AppContext = createContext();

export const initialState = {
  username: "",  // username del usuario logeado
  userview: "",  // vista de alumno (solo para staff)
  isStaff: null,
  readOnly: false,
  course: "",
}

export const appReducer = (state, action)=>{
  switch(action.type){
    case "setUsername":{
      return {
        ...state,
        username: action.payload,
      }
    }
    case "setUserview":{
      return {
        ...state,
        userview: action.payload,
      }
    }
    case "setIsStaff":{
      return {
        ...state,
        isStaff: action.payload,
      }
    }
    case "setReadOnly":{
      return {
        ...state,
        readOnly: action.payload,
      }
    }
    case "setCourse":{
      return {
        ...state,
        course: action.payload,
      }
    }
    case "setShowCongrats":{
      return {
        ...state,
        showCongrats: action.payload,
      }
    }
    default:
      return state;
  }
}
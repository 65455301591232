import { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { AppContext } from "../appInfo";

import Layout from "../components/Layout";
import Header from "../components/Header";

import cup from "../assets/img/cup.svg";
import explevel from "../assets/img/xp.png";
import medal from "../assets/img/medal.png";
import fire from "../assets/img/fire.png";

import MiniCard from "../components/MiniCard";

import LinearProgress from "@mui/material/LinearProgress";

import { StudentProgressAPI } from "../api/StudentProgressAPI";
import { StudentAwardsAPI } from "../api/StudentAwardsAPI";

import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

function PantallaProgreso() {
  const { course } = useParams();

  const { state } = useContext(AppContext);
  const { username, isStaff } = state;

  const navigate = useNavigate();

  const [levelNumber, setLevelNumber] = useState("---");
  const [levelProgress, setLevelProgress] = useState(0);

  const [streak, setStreak] = useState("---");
  const [experience, setExperience] = useState("---");

  const [pointsAvailable, setPointsAvailable] = useState("---");
  const [pointsUsed, setPointsUsed] = useState("---");

  const [awards, setAwards] = useState("CARGANDO...");
  const [awardAcquireDialog, setAwardAcquireDialog] = useState(false);
  const [awardAcquireId, setAwardAcquireId] = useState(0);
  const [awardAcquirePoints, setAwardAcquirePoints] = useState(0);

  useEffect(() => {
    if (!username) {
      return;
    }
    StudentProgressAPI.getAll(username, course).then((data) => {
      setLevelNumber(data["level_number"]);
      setLevelProgress(data["level_progress"]);
      setStreak(data["current_streak"]);
      setExperience(data["total_experience"]);
    });
  }, [username, course]);

  useEffect(() => {
    if (!username) {
      return;
    }
    StudentAwardsAPI.points(username).then((data) => {
      setPointsAvailable(data["points_available"]);
      setPointsUsed(data["points_used"]);
    });

    return () => {
      //StudentAwardsAPI.abort() // cancel request
    };
  }, [username]);

  useEffect(() => {
    if (!username) {
      return;
    }

    StudentAwardsAPI.getAll(username).then((data) => {
      console.log(data);
      setAwards(data);
    });
    return () => {
      //StudentAwardsAPI.abort() // cancel request
    };
  }, [username, pointsAvailable]);

  const awardAcquire = () => {
    setAwardAcquireDialog(false);
    StudentAwardsAPI.acquire(username, awardAcquireId, awardAcquirePoints).then(
      (data) => {
        console.log(data);

        setPointsAvailable(data["points_available"]);
        setPointsUsed(data["points_used"]);
      }
    );
  };

  const awardColor = (is_available, claimed) => {
    if (is_available === false && claimed === null) {
      return "var(--gray)";
    } else if (is_available === true && claimed === null) {
      return "rgba(126, 244, 255, 0.5)";
    } else if (claimed === false) {
      return "var(--green)";
    } else if (claimed === true) {
      return "var(--gold)";
    }
  };

  const awardText = (is_available, claimed, points_needed) => {
    if (claimed === null) {
      return `Para adquirir este premio debe utilizar ${points_needed}pts de sus puntos disponibles.`;
    } else if (claimed === false) {
      return `Este premio ya fue adquirido. Utilice el siguiente código para notificarlo por whatsapp en su próxima compra.`;
    } else if (claimed === true) {
      return `Este premio ya fue utilizado.`;
    }
  };

  const awardButton = (is_available, claimed, id, points_needed, alias) => {
    if (is_available === false && claimed === null) {
      return (
        <Button size="large" variant="outlined" disabled>
          no disponible
        </Button>
      );
    } else if (is_available === true && claimed === null) {
      return (
        <Button
          size="large"
          variant="outlined"
          onClick={() => {
            setAwardAcquireDialog(true);
            setAwardAcquireId(id);
            setAwardAcquirePoints(points_needed);
          }}
        >
          ACTIVAR CUPON
        </Button>
      );
    } else if (claimed === false) {
      return (
        <Button size="large" color="success" variant="contained">
          CODIGO: {alias}
        </Button>
      );
    } else if (claimed === true) {
      return (
        <Button size="large" variant="outlined" disabled>
          premio utilizado
        </Button>
      );
    }
  };

  const gridCards = {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridGap: "30px",
    width: "100%",
  };

  const flexItem = {
    width: "100%",
    display: "flex",
    justifyContent: "start",
    alignItems: "start",
    flexDirection: "column",
    gap: "10px",
  };
  const gridItem = {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "start",
  };

  const explevelIcon = <img src={explevel} alt="mini" />
  const cupIcon = <img src={cup} alt="mini" />
  const fireIcon = <img src={fire} alt="mini" />
  const medalIcon = <img src={medal} alt="mini" />

  return (
    <Layout Header={<Header title={"Logros"} />} >
      {`Progreso de ${username}`}

      <MiniCard
        sx={{ width: "clamp(350px, 100%, 400px)" }}
        icon={explevelIcon}
        value={`Nivel: ${levelNumber} - Exp: ${experience}`}
        text={<LinearProgress variant="determinate" value={levelProgress} />}
      />

      <MiniCard
        sx={{
          width: "clamp(350px, 100%, 400px)",
          gridTemplateColumns: "20% 80%",
        }}
        icon={cupIcon}
        value={`Puntos disponibles: ${pointsAvailable}`}
        text={`Puntos utilizados: ${pointsUsed}`}
      />

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          alignItems: "center",
          padding: "10px",
          gap: "10px",
        }}
      >
        <div style={flexItem}>
          <div
            style={{
              fontSize: "clamp(1rem, 3vw, 1.3rem)",
              fontWeight: "bold",
            }}
          >
            Estadística general
          </div>
        </div>
        <div style={gridCards}>
          <div style={gridItem}>
            <MiniCard icon={fireIcon} value={streak} text={"Semanas racha"} />
          </div>
          <div style={gridItem}>
            <MiniCard icon={medalIcon} value={experience} text={"Experiencia"} />
          </div>
        </div>

        <Button
          size="medium"
          color="secondary"
          variant="outlined"
          onClick={() => navigate(`/cursos/${course}/progreso/experiencia`)}
        >
          Ver más estadísticas
        </Button>

        <div style={flexItem}>
          <div
            style={{
              fontSize: "clamp(1rem, 3vw, 1.3rem)",
              fontWeight: "bold",
            }}
          >
            Premios
          </div>
        </div>
        <div style={flexItem}>
          {Array.isArray(awards)
            ? awards.map((award) => (
                <Card
                  sx={{
                    width: "100%",
                    backgroundColor: awardColor(
                      award.is_available,
                      award.claimed
                    ),
                  }}
                  key={award.id}
                >
                  <CardContent>
                    <div
                      style={{
                        fontSize: "clamp(1.2rem, 3.5vw, 1.3rem)",
                        fontWeight: "bold",
                      }}
                    >
                      {award["name"]}
                    </div>
                    <p>
                      {awardText(
                        award.is_available,
                        award.claimed,
                        award.points_needed
                      )}
                    </p>
                  </CardContent>
                  <CardActions>
                    {awardButton(
                      award.is_available,
                      award.claimed,
                      award.id,
                      award.points_needed,
                      award.alias
                    )}
                  </CardActions>
                </Card>
              ))
            : awards}

          <Dialog
            open={awardAcquireDialog}
            onClose={() => setAwardAcquireDialog(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Adquirir premio"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                ¡Cuidado! Una vez que solicite el premio, no podrá recuperar los
                puntos utilizados para adquirirlo. ¿está seguro que desea
                continuar y utilizar {awardAcquirePoints}pts?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setAwardAcquireDialog(false)}>
                CANCELAR
              </Button>
              <Button onClick={awardAcquire} autoFocus>
                CONTINUAR
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    </Layout>
  );
}

export default PantallaProgreso;

import { useContext } from 'react'
import { useParams, Link } from "react-router-dom"
import { AppContext } from "../appInfo";

import styles from './NavBar.module.css'

// Icons
import info from "../assets/img/info.svg"
import coursepath from "../assets/img/coursepath.svg"
import cup from "../assets/img/cup.svg"
import metrics from "../assets/img/metrics.svg"
import feedback from "../assets/img/task.svg"
import video from "../assets/img/video.svg"


function NavBar({active}) {
  const { course } = useParams();
  const { state } = useContext(AppContext)
  const { isStaff } = state

  return (
    <nav className={styles.container}>
      <ul>
        {/* <li className={active ==="info"? `${styles.active}`:''}>
          <Link to={`/cursos/${course}/info`}><img src={info} alt="info"/></Link>
        </li> */}
        <li className={active ==="unidades"? `${styles.active}`:''}>
          <Link to={`/cursos/${course}/unidades`}><img src={coursepath} alt="unidades"/></Link>
        </li>
        {isStaff === true ?
        <li className={active ==="progreso"? `${styles.active}`:''}>
          <Link to={`/cursos/${course}/metricas`}><img src={metrics} alt="metricas"/></Link>
        </li>
        :
        <li className={active ==="metricas"? `${styles.active}`:''}>
          <Link to={`/cursos/${course}/progreso`}><img src={cup} alt="progreso"/></Link>
        </li>
        }
        <li className={active ==="devoluciones"? `${styles.active}`:''}>
          <Link to={`/cursos/${course}/devoluciones`}><img src={feedback} alt="devoluciones"/></Link>
        </li>
        {/* <li className={active ==="videos"? `${styles.active}`:''}>
          <Link to={`/cursos/${course}/videos`}><img src={video} alt="videos"/></Link>
        </li> */}
      </ul>
    </nav>
  )
}

export default NavBar
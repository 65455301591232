import { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { AppContext } from "../appInfo";

import Layout from "../components/Layout"
import Header from "../components/Header"

import checkGreen from "../assets/img/check_green.png"
import checkBlack from "../assets/img/check_black.png"

import MiniCard from "../components/MiniCard"
import RankingCard from "../components/progress/RankingCard"

import {CourseMetricsAPI} from "../api/CourseMetricsAPI"

import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Porcentaje participación',
    },
  },
  // scales: {
  //   yAxis: {
  //     min: 0,
  //     max: 100,
  //   },
  // },
};


function PantallaMetricas() {
  const { course } = useParams();

  const { state } = useContext(AppContext)
  const { username, isStaff } = state

  const navigate = useNavigate();

  const [graph, setGraph] = useState(null)

  const [studentsEnrolled, setStudentsEnrolled] = useState("---")
  const [studentsDeleted, setStudentsDeleted] = useState("---")
  const [students, setStudents] = useState([])

  // Mostrar esta panalla solo a staff
  useEffect( () =>{
    if(!isStaff) {
      return
    }

    if(isStaff === false) {
      navigate(`/cursos/${course}/unidades`)
    }
  }, [course, navigate, isStaff])

  useEffect( () =>{
    if(!username) {
      return
    }
    CourseMetricsAPI.get(username, course).then((data) => {
      console.log(data)
      setStudentsEnrolled(data["students_enrolled"])
      setStudentsDeleted(data["students_deleted"])
      setStudents(data["students_table"])

      const labels = data["achieved_lower_level"].map((element, index) => {
        return `U${index}`
      })

      const graphData = {
        labels,
        datasets: [
          {
            label: 'Obj Min',
            data: data["achieved_lower_level"],
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
          },
          {
            label: 'Obj Max',
            data: data["achieved_top_level"],
            borderColor: 'rgb(53, 162, 235)',
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
          },
          {
            label: 'Presentismo',
            data: data["attendance"],
            borderColor: 'rgb(53, 235, 140)',
            backgroundColor: 'rgba(53, 235, 140, 0.5)',
          },
        ],
      };

      setGraph(<Line options={options} data={graphData} />)
      
    });
    return () => {
      //StudentProgressAPI.abort() // cancel request
    }
  }, [username, course])

  const gridCards = {
    display: 'grid',
    gridTemplateColumns: "1fr 1fr",
    gridGap: '30px',
    width: "100%",
  }

  const gridItem = {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "start",
  }

  const flexItem = {
    width: "100%",
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    flexDirection: "column",
    gap: "15px",
  };

  const checkGreenIcon = <img src={checkGreen} alt="mini" />
  const checkBlackIcon = <img src={checkBlack} alt="mini" />

  return (
      <Layout 
        Header={<Header title={"Métricas"}/>}
      >

      <div style={gridCards}>
        <div style={gridItem}><MiniCard icon={checkGreenIcon} value={studentsEnrolled} text={"Alumnos cursando"} /></div>
        <div style={gridItem}><MiniCard icon={checkBlackIcon} value={studentsDeleted} text={"Alumnos abandonaron"} /></div>
      </div>

      {graph}

      <div style={flexItem}>
        {Array.isArray(students)
          ?
          students.map( (student, index) => (
              <RankingCard props={student} isStaff={true} key={index} />
            ))
            : null
        }
      </div>
      
      </Layout>
  )
}

export default PantallaMetricas
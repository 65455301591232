import { useState, useContext } from 'react'
import { useParams } from 'react-router-dom';
import { AppContext } from "../../appInfo";
import fileDownload from 'js-file-download'

import TextBox from '../TextBox'

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import toast from 'react-hot-toast';

import {StudentChallengeSolutionAPI} from "../../api/StudentChallengeSolutionAPI"

function ChallengeSolution({cid, solutionAvailable}) {
  const { course } = useParams();
  const { state } = useContext(AppContext)
  const { username } = state

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const downloadSolution = () => {
    setOpen(false);
    StudentChallengeSolutionAPI.getAll(username, course, cid).then((data) => {
      if(data["name"]) {
        // Si el response fue válido
        if(data["file_type"] !== "repository") {
          // Realizar la descarga de la solucion
          const filename = `${course}_${data["challenge_code"]}_resuelto.${data["file_extension"]}`;
          const filedata = data["solution"];
          fileDownload(filedata, filename);
        } else {
          window.open(data["solution"], "_blank")
        }

        if(data["experience"]) {
          console.log(data["experience"])
        }

      } else {
        toast.error("No se pudo descargar la solución");
      }
    });
  }

  return (
    <>
      <TextBox>
      {
        solutionAvailable?
        [
          
          "¡Cuidado! Una vez que solicite el desafío resuelto, este desafío no le dará más puntos / experiencia",
          "Si tiene dudas recuerde que puede pedir ayuda"
        ]
        :
        [
          "No hay solución disponible para descargar para este desafío"
        ]
      }
      </TextBox>
      <Button 
          variant="contained"
          color="success"
          disabled={solutionAvailable? false: true}
          size="large"
          onClick={handleClickOpen}
        >
        {solutionAvailable? "DESCARGAR RESUELTO": "RESUELTO NO DISPONIBLE"}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Descargar solución del desafío"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ¡Cuidado! Una vez que solicite el desafío resuelto, este desafío no le dará más puntos / experiencia,
            ¿Está seguro que desea continuar?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>CANCELAR</Button>
          <Button onClick={downloadSolution} autoFocus>CONTINUAR</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ChallengeSolution
import {useState} from 'react'

import styles from './Questionnaire.module.css'

import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Button from '@mui/material/Button';

import toast from 'react-hot-toast';
import ReactMarkdown from 'react-markdown'
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter'

function Questionnaire({data, idx, handleChoice}) {
  const [choice, setChoice] = useState(null)

  const handleRadioGroupChange = (e) => {
    setChoice(e.target.value)
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if(choice === null) {
      toast.error("Debe seleccionar una opción para continuar");
      return;
    }
    
    const res = handleChoice(idx, data.answers.filter(x => x.id === Number(choice)))
    if(!res) {
      toast.error("Error al seleccionar respuesta, vuelva a intentarlo");
      setChoice(null);
      return;
    }
  };

  return (
      <div className={styles.container}>
          <div className={styles.statementContainer}>{data.statement}</div>
          {data.code &&
              <ReactMarkdown
                children={`\`\`\`javascript\n${data.code}\n\`\`\``}
                components={{
                  code({node, inline, className, children, ...props}) {
                    const match = /language-(\w+)/.exec(className || '')
                    return !inline && match ? (
                      <SyntaxHighlighter
                        children={String(children).replace(/\n$/, '')}
                        // style={dark}
                        language={match[1]}
                        wrapLongLines={true}
                        PreTag="div"
                        {...props}
                      />
                    ) : (
                      <code className={className} {...props}>
                        {children}
                      </code>
                    )
                  }
                }}
              />
          }
          <FormLabel id="demo-row-radio-buttons-group-label"><strong>
          {data.type === 1?
            "Seleccione la respuesta correcta"
            :
            "Seleccione una o varias respuestas correctas (multiple-choice)"
          }
          </strong></FormLabel>
          <form onSubmit={handleSubmit} style={{width: "100%"}}>
            <FormControl fullWidth style={{gap: "20px"}}>
              
              <RadioGroup
                row
                value={choice}
                onChange={handleRadioGroupChange}
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                style={{flexDirection: "column", gap: "20px"}}
              >
                {Array.isArray(data.answers)
                ?
                  data.answers.map( answer => (
                      <FormControlLabel value={answer.id} key={answer.id} control={<Radio />} label={answer.text} />
                  ))
                  : null
                }
              </RadioGroup>
              <Button 
                  variant="contained"
                  color="success"
                  size="large"
                  type="submit"
                >
                SIGUIENTE
              </Button>
            </FormControl>
          </form>
        </div>
  )
}

export default Questionnaire
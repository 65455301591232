import {useState, useEffect, useContext} from 'react'
import { useParams } from "react-router-dom";
import { AppContext } from "../appInfo";

import Layout from "../components/Layout"
import Header from "../components/Header"

import TabGroup from "../components/TabGroup"
import ChallengeStatement from "../components/challenge/ChallengeStatement"
import ChallengeSubmission from "../components/challenge/ChallengeSubmission"
import ChallengeProblem from "../components/challenge/ChallengeProblem"
//import ChallengeTest from "../components/challenge/ChallengeTest"
import ChallengeUpload from "../components/challenge/ChallengeUpload"
import ChallengeDWTest from "../components/challenge/ChallengeDWTest"
import ChallengeSolution from "../components/challenge/ChallengeSolution"
import ChallengeHelp from "../components/challenge/ChallengeHelp"

import {StudentChallengeDetailAPI} from "../api/StudentChallengeDetailAPI"

function PantallaDesafio() {
  const { cid } = useParams();
  const [tabIndex, setTabIndex] = useState(0)
  const [data, setData] = useState(null)

  const [score, setScore] = useState("")
  const [bonus, setBonus] = useState(0)
  const [completed, setCompleted] = useState(false)

  const { course } = useParams();
  const { state, } = useContext(AppContext)
  const { userview } = state

  useEffect( () =>{
    if(!userview) {
      return
    }
    StudentChallengeDetailAPI.getAll(userview, course, cid).then((data) => {
      setData(data)
      setScore(data["score"])
      if(data["score"]) {
        setBonus(data["bonus"])
        setCompleted(data["completed"])
      }
    });
    return () => {
      //StudentChallengeStatementAPI.abort() // cancel request
    }
  }, [course, cid, userview])


  return (
      <Layout 
        Header={<Header title={"Desafío"} linkType={"back"}/>}
      >
      {data?
        <TabGroup
          tabs={
            [
              {
                "label": "ENUNCIADO",
                "content":
                  <>
                  <ChallengeSubmission cid={cid} challengeCode={data["challenge_code"]} score={score} bonus={bonus} completed={completed}  />
                  <ChallengeStatement cid={cid} challengeCode={data["challenge_code"]} statement={data["statement"]}/>
                  <ChallengeProblem cid={cid} />
                  </>
                },
                {
                  "label": "ENTREGA",
                  "content": data["challenge_type"] === "DWChallenge" ?
                      <ChallengeDWTest cid={cid} isRepository={data["file_type"] === "repository"} setScore={setScore} setBonus={setBonus} setCompleted={setCompleted}/>
                      :
                      <ChallengeUpload cid={cid} isRepository={data["file_type"] === "repository"} setScore={setScore} setBonus={setBonus} setCompleted={setCompleted}/>
                },
                {
                  "label": "RESUELTO",
                  "content":  <ChallengeSolution cid={cid} solutionAvailable={data["solution_available"]}/>
                },
                {
                  "label": "AYUDA",
                  "content": <ChallengeHelp cid={cid}/>
                },
              ]
          }
          tabIndex={tabIndex} setTabIndex={setTabIndex}/>
        :
        "CARGANDO..."
      }
      </Layout>
  )
}

export default PantallaDesafio
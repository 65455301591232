import { useState, useContext} from 'react'
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../appInfo";

import toast from 'react-hot-toast';
import styles from "./Unit.module.css";

import Quest from "./Quest";
import FeedbackButton from "./FeedbackButton";
import FeedbackChip from "./FeedbackChip";
import IconTextButton from "../IconTextButton";

import Divider from "@mui/material/Divider";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';

function Unit({ props }) {
  const { state } = useContext(AppContext)
  const { isStaff } = state

  const navigate = useNavigate();
  const [feedbackStatus, setFeedbackStatus] = useState(props.feedback ? props.feedback.status : 0)

  let status = "";
  if (props.visible === false) {
    status = `${styles.disabled}`;
  } else {
    if (props.completed === true) {
      status = `${styles.completed}`;
    } else {
      status = `${styles.inProgress}`;
    }
  }

  const handleApuntesClick = () => {
    if(!props.visible && !isStaff) {
      toast.error("La unidad aún no está habiliada");
      return
    }
    navigate(`${window.location.pathname}/${props.idx}/apuntes`)
  }

  const handleClaseGrabadaClick = () => {
    if(!props.visible && !isStaff) {
      toast.error("La unidad aún no está habiliada");
      return
    }
    if(!props.class_record_available) {
      toast.error("La clase grabada aún no está lista");
      return
    }
    window.open(props.class_videos_link, '_blank');
  }

  const btn1 = <IconTextButton
    icon={<ImportContactsIcon sx={{ fontSize: 40 }}/>}
    text={"Apuntes"}
    disabled={props.visible? false:true}
    onClick={() => handleApuntesClick()}
    />
  const btn2 = <IconTextButton
    icon={<OndemandVideoIcon sx={{ fontSize: 40 }}/>}
    text={"Clase grabada"}
    disabled={props.class_record_available? false:true}
    onClick={() => handleClaseGrabadaClick()}
    />
  const btn3 = props.feedback ? <FeedbackButton props={props.feedback} status={feedbackStatus} setStatus={setFeedbackStatus} challengesVisible={props.challenges_visible} /> : null

  return (
    <div className={styles.container}>
      <div className={`${styles.banner} ${status}`}>
        <div className={styles.bannerRow}>
          <div className={styles.title}>
            <h1>Unidad {props.idx}</h1>
            <h2>{props.name}</h2>
          </div>
          <div className={styles.dateText}>
            <h2>{props.class_date}</h2>
          </div>
        </div>
        <div className={styles.buttonsContainer}>
          {btn1}
          {btn2}
          {btn3}
        </div>
        <Divider variant="middle" />
        <div className={styles.bannerRow}>
          {props.feedback && props.challenges_visible ? <FeedbackChip props={props.feedback} status={feedbackStatus} setStatus={setFeedbackStatus} /> : null}
        </div>
      </div>

      <div className={styles.questContainer}>
        {Array.isArray(props.quests)
          ? props.quests.map((quest) => (
              <Quest
                props={quest}
                goal_level={props.feedback_lower_level}
                key={quest.qid}
              />
            ))
          : null}
      </div>
    </div>
  );
}

export default Unit;

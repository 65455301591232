import styles from './TextBox.module.css'

import ReactMarkdown from 'react-markdown'
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter'

function TextBox({children}) {

  let texts = [];
  if(Array.isArray(children)) {
    texts = children
  }
  else {
    texts.push(children);
  }

  // function replaceAll(str, find, replace) {
  //   return str.replace(new RegExp(find, 'g'), replace);
  // }

  return (
    <div className={styles.container}>
      {
        texts?.map((text, index) => (
        <div 
          className={`${styles.section} ${"swiper-no-swiping"}`} 
          tabIndex="1" 
          key={index}
          >
            <ReactMarkdown
              children={text}
              components={{
                code({node, inline, className, children, ...props}) {
                  const match = /language-(\w+)/.exec(className || '')
                  return !inline && match ? (
                    <SyntaxHighlighter
                      children={String(children).replace(/\n$/, '')}
                      // style={dark}
                      language={match[1]}
                      wrapLongLines={true}
                      PreTag="div"
                      {...props}
                    />
                  ) : (
                    <code className={className} {...props}>
                      {children}
                    </code>
                  )
                }
              }}
            />
        </div>
        ))
      }
    </div>
  )
}

export default TextBox
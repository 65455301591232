import {useState, useEffect, useContext} from 'react'
import { useParams } from "react-router-dom";
import { AppContext } from "../../appInfo";

import DropDown from '../DropDown'
import TextBox from '../TextBox'

import {FeedbackChallengesAPI} from "../../api/FeedbackChallengesAPI"

import Button from '@mui/material/Button';

function FeedbackQuestCodeScript({props}) {
  const { course, fid } = useParams();
  const [challenges, setChallenges] = useState("CARGANDO...")
  const { state } = useContext(AppContext)
  const { username } = state

  const handlePreview = (previewUrl) => {
    window.open(previewUrl, '_blank');
  };

  useEffect( () =>{
    if(!username) {
      return
    }

    FeedbackChallengesAPI.getAll(username, course, fid, props.qid).then((data) => {
      //console.log(data)

      const challenges = []
      for (const challenge of data) {
        let code_type = ""
        switch(challenge.challenge_type) {
            case "PythonFile": code_type = "python";break;
            case "PythonChallenge": code_type = "python";break;
            default: break;
        }

        challenges.push(
          <TextBox key={challenge.challenge_id} >
            {`Desafío #${challenge.challenge_code} - puntos: ${challenge.score}\n\`\`\`${code_type}\n${challenge.solution}\n\`\`\``}
          </TextBox>
        )

        if(challenge.preview_url) {
          challenges.push(<Button variant="contained" color="secondary" size="large" type="submit" onClick={() => handlePreview(challenge.preview_url)} key={`preview_${challenge.challenge_id}`} > VISTA PREVIA </Button> )
        }
        
      }
      setChallenges(challenges)

    });
    return () => {
      //FeedbackChallengesAPI.abort() // cancel request
    }
  }, [username, course, fid, props])

  const status = props.completed? "completed" : "inProgress"
  const title = `Misión ${props.level} (${Number(props.progress*100)}%)`

  return (
    <DropDown title={title} status={status} >
      {challenges}
    </DropDown>
  )
}

export default FeedbackQuestCodeScript
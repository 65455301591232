import { api } from "./client"

const controller = new AbortController();

export const StudentChallengeTestAPI = {
  test: async function (username, course, challenge_id, solution) {
    const response = await api.request({
      url: `/student/${username}/course/${course}/challenges/${challenge_id}/test/`,
      method: "POST",
      data: {
        solution: solution
      },
      signal: controller.signal
    })
    if(response) {
      return response.data
    }
  },
  evaluate: async function (username, course, challenge_id, solution) {
    const response = await api.request({
      url: `/student/${username}/course/${course}/challenges/${challenge_id}/evaluate/`,
      method: "POST",
      data: {
        solution: solution
      },
      signal: controller.signal
    })
    if(response) {
      return response.data
    }
  },
  abort: function(){
    controller.abort()
  },
}
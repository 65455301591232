import {useState, useEffect, useContext} from 'react'
import { useParams } from "react-router-dom";
import { AppContext } from "../appInfo";

import Layout from "../components/Layout"
import Header from "../components/Header"
import FeedbackCard from "../components/feedback/FeedbackCard"
import FeedbackQuestCodeScript from "../components/feedback/FeedbackQuestCodeScript"

import {FeedbackDetailAPI} from "../api/FeedbackDetailAPI"
import {FeedbackQuestListAPI} from "../api/FeedbackQuestListAPI"
import {FeedbackReceivedAPI} from "../api/FeedbackReceivedAPI"

import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Button from '@mui/material/Button';

import toast from 'react-hot-toast';


function PantallaDevolucionProfe() {
  const { course, fid } = useParams();
  const [feedbackCard, setFeedbackCard] = useState(null)
  const [feedbackCompleted, setFeedbackCompleted] = useState(false)
  const [points, setPoints] = useState('')
  const [feedbackText, setFeedbackText] = useState('')
  const [quests, setQuests] = useState("CARGANDO...")
  const [errorTextField, setErrorTextField] = useState(false);
  const { state } = useContext(AppContext)
  const { username } = state

  const minFeedbackTextLength = 10;

  const handlerSetFeedback = (feedback) => {
    return <FeedbackCard
            fid={null} // Para que no haga el navigateTo
            title={feedback.title}
            completed={feedback.completed}
            points={feedback.points}
            rating={feedback.rating_score}
            rating_text={feedback.rating_text}
            user={feedback.user}
            date={feedback.date}
            userview={feedback.userview}
          />
  }

  useEffect( () =>{
    if(!username) {
      return
    }
    FeedbackDetailAPI.getAll(username, course, fid).then((feedback) => {
      // console.log(feedback)
      setPoints(feedback.points)
      setFeedbackText(feedback.feedback)
      setFeedbackCompleted(feedback.completed)
      setFeedbackCard(handlerSetFeedback(feedback))
    });
    return () => {
      //FeedbackListAPI.abort() // cancel request
    }
  }, [course, fid, username])


  useEffect( () =>{
    if(!username) {
      return
    }
    FeedbackQuestListAPI.getAll(username, course, fid).then((quests) => {
      // console.log(quests);
      setQuests(quests);
    });
    return () => {
      //FeedbacksQuestListAPI.abort() // cancel request
    }
  }, [username, course, fid])

  const handleTextFieldChange = (e) => {
    setFeedbackText(e.target.value);
  };

  const handleRadioGroupChange = (e) => {
    setPoints(e.target.value)
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if(feedbackText.length < minFeedbackTextLength) {
      setErrorTextField(true);
      toast.error("Devolución muy corta");
      return;
    }
    if(points === null) {
      toast.error("Debe calificar la devolución");
      return;
    }
    setErrorTextField(false);
    
    let apiMethod = FeedbackReceivedAPI.create;
    if(feedbackCompleted === true) {
      apiMethod = FeedbackReceivedAPI.update;
    }

    const feedback_received = {
      points: points,
      feedbackText: feedbackText,
      feedback_link: `${window.location.protocol}//${window.location.hostname}/cursos/${course}/devoluciones/alumno/${fid}`
    }

    toast.success("Enviando devolución....", { icon: '🌠' });
    apiMethod(username, course, fid, feedback_received).then((feedback) => {
      setFeedbackCard(handlerSetFeedback(feedback))
      toast.success("¡Devolución enviada!");
    });
    return () => {
      //FeedbacksQuestListAPI.abort() // cancel request
    }
  };

  return (
      <Layout 
        Header={<Header linkType={"back"}/>} 
      >
        <div style={{
          overflow:"visible",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "20px",
        }}
        >
          {feedbackCard}
          {Array.isArray(quests)
          ?
              quests.map( quest => (
              <FeedbackQuestCodeScript props={quest} key={quest.qid} />
            ))
            : quests
          }
          <form onSubmit={handleSubmit}>
            <FormControl fullWidth>
              <FormLabel id="demo-row-radio-buttons-group-label">Devolución</FormLabel>
              <TextField
                id="feedback-text"
                label="Devolución:"
                placeholder={`escribir al menos ${minFeedbackTextLength} caracteres`}
                multiline
                fullWidth
                margin="normal"
                rows={4}
                required={true}
                error={errorTextField}
                value={feedbackText}
                onChange={handleTextFieldChange}
                onInput = {(e) =>{
                  if(e.target.value.length < minFeedbackTextLength) {
                    setErrorTextField(true);
                  }
                  else {
                    setErrorTextField(false);
                  }
                }}
                // inputProps={{ maxLength: 3 }}
              />
              <RadioGroup
                row
                value={points}
                onChange={handleRadioGroupChange}
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
              >
                <FormControlLabel value="0" control={<Radio />} label="+0 - No alcanzó el objetivo" />
                <FormControlLabel value="5" control={<Radio />} label="+5 - Múltiples errores de código" />
                <FormControlLabel value="8" control={<Radio />} label="+8 - Solo recomendaciones de sintaxis" />
                <FormControlLabel value="10" control={<Radio />} label="+10 - Excelente, sin recomendaciones" />
              </RadioGroup>
              <Button 
                  variant="contained"
                  color="success"
                  size="large"
                  type="submit"
                >
                {feedbackCompleted? "ACTUALIZAR DEVOLUCIÓN" : "ENVIAR DEVOLUCIÓN"}
              </Button>
            </FormControl>
          </form>
        </div>
      </Layout>
  )
}

export default PantallaDevolucionProfe
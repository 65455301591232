import {useState} from 'react'
import { useParams, useNavigate } from "react-router-dom";

import FeedbackRating from "./FeedbackRating"

import Card from '@mui/material/Card';
import { CardActionArea } from '@mui/material';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';

import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { styled } from '@mui/material/styles';
import { red } from '@mui/material/colors';

import styles from './FeedbackCard.module.css'

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

function FeedbackPoints({points, completed, userview}) {

  let points_str = `+${points}`;
  let backgroundColor = "var(--gold)";
  let color = "var(--black)";
      
  if(userview === false && completed === false) {
    // El profesor no ha califcado la entrega
    points_str = "¿?";
    backgroundColor = "var(--purple)";
    color = "var(--gray)";
  }

  return (
    <Box
      sx={{
        width: "50px",
        height: "50px",
        border: "0",
        borderRadius: "50%",
        backgroundColor: backgroundColor,
        color: color,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: "absolute",
        top: "-5px",
        right: "-25px",
        fontSize: 'clamp(1rem, 2vw, 1.2rem)'
      }}
    >
      {points_str}
    </Box>
  );
}

function FeedbackMessage({message}) {

  // Hay que instalar styled componentes e importar arriba
  //import { keyframes } from "styled-components";

  // const spin = keyframes`
  //   0% { transform: rotate(0deg); }
  //   100% { transform: rotate(360deg); }`;

  return (
    // <Box
    //   sx={{
    //     width: "100%",
    //     height: "100%",
    //     display: 'flex',
    //     alignItems: 'center',
    //     justifyContent: 'center',
    //     fontSize: 'clamp(1.2rem, 3.5vw, 1.5rem)',
    //     animation: `${spin} 2s linear infinite`
    //   }}
    // >
    //   {message}
    // </Box>
    <div className={styles.message}>{message}</div>
  );
}


function UnitTitle({title, completed, userview}) {
  let background_color = "var(--gray)";
  let color = "var(--black)";
  if(completed === true) {
    background_color = "var(--green)";
    color = "#fff"
  }

  return (
    <Box
      sx={{
        width: "100%",
        padding: "16px",
        backgroundColor: background_color,
        color: color,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 'clamp(1.2rem, 3.5vw, 1.5rem)'
      }}
    >
      {title}
    </Box>
  );
}


export default function FeedbackCard({fid, title, completed, points, rating, rating_text, user, date, userview}) {
  const { course } = useParams();
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  
  const handleContent = () => {
    let inProgressMessage = userview? "CALIFICAR": "EMPEZAR DEVOLUCIÓN"
    if(completed === true) {
      return <FeedbackRating value={rating} disabled={true}/>
    }
    if(fid != null) {
      return <FeedbackMessage message={inProgressMessage} />
    }
  }

  const navigateTo = () => {
    if(fid == null) {
      return;
    }

    if(userview === true){
      navigate(`/cursos/${course}/devoluciones/alumno/${fid}`)
    }
    else {
      navigate(`/cursos/${course}/devoluciones/profesor/${fid}`)
    }
  }

  return (
    <Card sx={{ width: "clamp(350px, 80%, 400px)", position:"relative", overflow:"visible" }}>
      <FeedbackPoints points={points} completed={completed} userview={userview} />
      <UnitTitle title={title} completed={completed} userview={userview}/>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
            {user[0].toUpperCase()}
          </Avatar>
        }
        title={user}
        subheader={date}
      />
      <CardActionArea
        onClick={()=>navigateTo()}
      >
        <CardContent>
          {handleContent()}      
        </CardContent>
      </CardActionArea>
      {rating_text &&
        <CardContent sx={{display:"flex", justifyContent: "flex-end"}}>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
        </CardContent>
        }  
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          {rating_text}
        </CardContent>
      </Collapse>
    </Card>
    
  );
}

import { useParams } from "react-router-dom"

import Layout from "../components/Layout"
import Header from "../components/Header"
import NavBar from "../components/NavBar"

function PantallaVideos() {
  const { course } = useParams();

  return (
      <Layout 
        Header={<Header />} 
        Footer={<NavBar active={"videos"}/>}
      >
        <div>
          VIDEOS {course}
        </div>
      </Layout>
  )
}

export default PantallaVideos
import { api } from "./client"

const controller = new AbortController();

export const StudentAwardsAPI = {
  getAll: async function (username) {
    
    const response = await api.request({
      url: `/student/${username}/awards/`,
      method: "GET",
      signal: controller.signal
    })
    if(response) {
      return response.data
    }
  },
  points: async function (username) {
    
    const response = await api.request({
      url: `/student/${username}/awards/points/`,
      method: "GET",
      signal: controller.signal
    })
    if(response) {
      return response.data
    }
  },
  acquire: async function (username, id, points_used) {
      const response = await api.request({
        url: `/student/${username}/awards/acquire/`,
        method: "POST",
        data: {
          "id": id,
          "points_used": points_used
        },
        signal: controller.signal
      })
      if(response) {
        return response.data
      }
  },
  abort: function(){
    controller.abort()
  },
}

import {useState, useEffect, useRef} from 'react'

import styles from './Congrats.module.css'
import "./Congrats.css"

import medal from "../assets/img/medal.png"

// Animaciones
import Grow from '@mui/material/Grow';

import CloseIcon from '@mui/icons-material/CloseOutlined';
// import Button from '@mui/material/Button';
// import ShareIcon from '@mui/icons-material/Share';

import Confetti from 'react-confetti'

// https://codepen.io/kazeidesign/pen/QNVNEm
// https://codepen.io/kareem-mahmoud/pen/abpamZp
// https://codepen.io/masudrana2779/pen/QWxeRVM
// Compartir en redes:
// https://snappywebdesign.net/blog/how-to-code-a-social-share-button-with-material-ui/

function Congrats({ message, points, bonusPoints, extrapoints }) {
  const [inDelay, setInDelay] = useState(false)
  const delay = 300;

  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const elementRef = useRef(null);


  useEffect(() => {
    setHeight(elementRef.current.clientHeight);
    setWidth(elementRef.current.clientWidth-20);
    setTimeout(() => setInDelay(true), delay)  
  }, [])

  return (
      <div className={styles.container} ref={elementRef}>
        <div className={styles.background}></div>
        <CloseIcon 
          className={styles.closeIcon} 
          fontSize={'large'} 
          onClick={ () => { elementRef.current.style.display = "none"; }}
        />
        <Confetti
          width={width}
          height={height-25}
          opacity ={0.6}
          style={{ top: "20px", left:"5px", zIndex: '-100' }}
        />
        <div>
          <img className={styles.medal} src={medal} alt="Medalla" />
          <p className={styles.congratsMsg}>{message}</p>
        </div>
        
        <div className={styles.pointsContainer}>
          <div className={styles.pointsSection}>
            <div className={styles.pointsRow}>
              {points > 0 &&
                <Grow
                  in={points > 0 && inDelay}
                  style={{ transformOrigin: '100 0 0' }}
                  timeout={{ enter: 500 }}
                  // {...(points > 0 ? { timeout: 500 } : {})}
                >
                  <div className={`${styles.points} ${styles.exp}`}>+{points} exp</div>
                </Grow>
              }
              {bonusPoints > 0 &&
                <Grow in={bonusPoints > 0 && inDelay}
                  style={{ transformOrigin: '100 0 0' }}
                  timeout={{ enter: 1500 }}
                >
                  <div className={`${styles.points} ${styles.bonus}`}>+{bonusPoints} bonus</div>
                </Grow>
              }
            </div>
              {extrapoints > 0 &&
                <Grow in={extrapoints > 0 && inDelay}
                  style={{ transformOrigin: '100 0 0' }}
                  timeout={{ enter: 2000 }}
                >
                  <div className={`${styles.points} ${styles.superbonus}`}>+{extrapoints} super bonus</div>
                </Grow>
              }
          </div>
            {/* <Button
              variant="contained"
              color="success"
              size="large"      
              // onClick={startChallenge}
            >
              <ShareIcon />
              COMPARTIR
            </Button> */}
        </div>

      </div>
  )
}

export default Congrats
import {useState, useEffect, useContext} from 'react'
import { useParams, useNavigate } from "react-router-dom";
import { AppContext } from "../appInfo";

import Layout from "../components/Layout"
import Header from "../components/Header"

import RestartQuestChip from '../components/units/RestartQuestChip';
import TabGroup from "../components/TabGroup"
import Questionnaire from "../components/questionnaire/Questionnaire"
import QuestionnaireTest from "../components/questionnaire/QuestionnaireTest"

import Chip from '@mui/material/Chip';
import VisibilityIcon from '@mui/icons-material/Visibility';

import {StudentQuestMainChallengeAPI} from "../api/StudentQuestMainChallengeAPI"

function PantallaCuestionario() {
  const { course, qid } = useParams();
  const { state } = useContext(AppContext)
  const { username, isStaff } = state

  const navigate = useNavigate();

  const [tabIndex, setTabIndex] = useState(0)
  const [questionnaireID, setQuestionnaireID] = useState(0)
  const [questions, setQuestions] = useState(null);
  const [choices, setChoices] = useState([])

  const handleChoice = (idx, choice) => {
    if(choice?.length === 0) {
      return false
    }
    const newChoices = [...choices]
    newChoices[idx] = {
      'id': questions[idx].id,
      'text': choice[0]['text'],
      'selected': String(choice[0]['id']),
    }
    setChoices(newChoices)
    setTabIndex(idx+1)
    return true
  }

  const createTabQuestions = () => {
    const newTabs = questions.map((question, index) => {
      return  ({
          "label": `P${index+1}`, 
          "content":
            <>
            <Questionnaire data={question} idx={index} handleChoice={handleChoice}/>
            </>
          }
        )
    });
    newTabs.push({
      "label": "Resultado", 
      "content":
          <>
          <QuestionnaireTest cid={questionnaireID} choices={choices} />
          </>
    })
    return <TabGroup tabs={newTabs} tabIndex={tabIndex} setTabIndex={setTabIndex}/>
  }

  useEffect( () =>{
    if(!username) {
      return
    }

    StudentQuestMainChallengeAPI.getAll(username, course, qid).then((data) => {
      // console.log(data)
      setQuestionnaireID(data["cid"])
      setQuestions(data["questions"])
      setChoices(new Array(data["questions"].length).fill(null))
    });
    return () => {
      //StudentQuestMainChallengeAPI.abort() // cancel request
    }

  }, [course, qid, username])

  const showChip = <Chip
      icon={<VisibilityIcon />}
      label={"Respuestas"}
      onClick={() => navigate(`${window.location.pathname}/solucion`)}
      color="primary"
    />

  return (
      <Layout 
        Header={
          <Header
            title={"Cuestionario"}
            linkType={"back"}
            btn1={isStaff? showChip : null}
            btn2={isStaff? <RestartQuestChip qid={qid} /> : null}
          />
        }
      >
      {Array.isArray(questions)
        ?
        createTabQuestions()     
        : "CARGANDO..."
      }
      </Layout>
  )
}

export default PantallaCuestionario
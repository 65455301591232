import {useState, useEffect, useContext} from 'react'
import { useParams, useNavigate } from "react-router-dom";
import { AppContext } from "../appInfo";

import Layout from "../components/Layout"
import Header from "../components/Header"

import {StudentQuestMainChallengeAPI} from "../api/StudentQuestMainChallengeAPI"

import styles from  '../components/questionnaire/Questionnaire.module.css'

import ReactMarkdown from 'react-markdown'
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter'

function PantallaCuestionarioSolucion() {
  const { course, qid } = useParams();
  const { state } = useContext(AppContext)
  const { username, isStaff } = state

  const navigate = useNavigate();

  const [questions, setQuestions] = useState(null);

  const createQuestions = (question) => {
    return (
      <div key={question.id} className={styles.statementContainer}>
        <p>{question.statement}</p>
        {question.code &&
              <ReactMarkdown
                children={`\`\`\`javascript\n${question.code}\n\`\`\``}
                components={{
                  code({node, inline, className, children, ...props}) {
                    const match = /language-(\w+)/.exec(className || '')
                    return !inline && match ? (
                      <SyntaxHighlighter
                        children={String(children).replace(/\n$/, '')}
                        // style={dark}
                        language={match[1]}
                        wrapLongLines={true}
                        PreTag="div"
                        {...props}
                      />
                    ) : (
                      <code className={className} {...props}>
                        {children}
                      </code>
                    )
                  }
                }}
              />
          }
        {Array.isArray(question.answers)
          ?
          question.answers.map( (answer, index) => (
              <div key={answer.id}>
                <p>------------- Respuesta correcta nº{index+1}  -------------</p>
                <p>{answer.text}</p>
              </div>
          ))
          : null
        }
      </div>
    )
  }

  // Mostrar esta panalla solo a staff
  useEffect( () =>{
    if(isStaff === false) {
      navigate(`/cursos/${course}/unidades`)
    }
  }, [course, navigate, isStaff])

  useEffect( () =>{
    if(!username) {
      return
    }
    StudentQuestMainChallengeAPI.solution(username, course, qid).then((data) => {
      console.log(data)
      setQuestions(data["questions"])
    });
    return () => {
      //StudentQuestMainChallengeAPI.abort() // cancel request
    }

  }, [course, qid, username])

  return (
      <Layout 
        Header={
          <Header
            title={"Solución del cuestionario"}
            linkType={"back"}
          />
        }
      >
      <div className={styles.container}>
        {Array.isArray(questions)
          ?
          questions.map( question => (
            createQuestions(question)
          ))
          : "CARGANDO..."
        }
      </div>
      </Layout>
  )
}

export default PantallaCuestionarioSolucion
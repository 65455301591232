import { useParams, useNavigate } from "react-router-dom";

import styles from './ChallengeCard.module.css'

import CircularWithValueLabel from "../CircularProgressWithLabel"


import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';





function BonusPoints({points}) {

  const points_str = `+${points}`;
  const backgroundColor = "var(--purple)";
  const color = "#fff";

  return (
    <Box
      sx={{
        width: "50px",
        height: "50px",
        border: "0",
        borderRadius: "50%",
        backgroundColor: backgroundColor,
        borderColor: "rgb(0,0,0)",
        color: color,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: "absolute",
        top: "-5px",
        right: "-25px",
        fontSize: 'clamp(1rem, 2vw, 1.2rem)'
      }}
    >
      {points_str}
    </Box>
  );
}

export default function ChallengeCard({props}) {
  const { course } = useParams();
  const navigate = useNavigate();

  let dificultad = "--";
  if(props.level === 1) {
    dificultad = "BAJA";
  }
  else if(props.level === 2) {
    dificultad = "MEDIA";
  }
  else if(props.level === 3) {
    dificultad = "ALTA";
  }
  else if(props.level === 4) {
    dificultad = "MUY ALTA";
  }

  let status = "";
  let status_text = "";
  if (props.closed === false && props.inProgress === false) {
    status = `${styles.disabled}`;
    status_text = "SIN COMENZAR";
  } else {
    if (props.closed === true) {
      status = `${styles.completed}`;
      status_text = "COMPLETADO";
    } else {
      status = `${styles.inProgress}`;
      status_text = "EN PROGRESO";
    }
  }

  return (
    <Card sx={{ width: "clamp(350px, 80%, 700px)", position:"relative", overflow:"visible" }}>
      {props.bonus? <BonusPoints points={props.bonus} /> : null}
      <div className={`${styles.title} ${status}`}>
        {`Desafío ${props.code}`}
      </div>
      <CardHeader
        avatar={
          <CircularWithValueLabel value={props.score}/>
        }
        title={`Estado: ${status_text}`}
        subheader={`Dificultad: ${dificultad}`}
      />
      <CardContent sx={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
        <Button
          variant="contained"
          color="success"
          sx={{width: "80%"}}
          onClick={() => navigate(`/cursos/${course}/desafios/${props.id}`)}
        >
          INGRESAR
        </Button>
      </CardContent>
    </Card>
    
  );
}

import { useState, useEffect, useContext } from 'react'
import { useParams, Outlet, useNavigate } from 'react-router-dom';
import { AppContext } from "../appInfo";

import styles from "./ResponsiveDrawer.module.css";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Avatar from '@mui/material/Avatar';

import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import InfoIcon from '@mui/icons-material/Info';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import ForumIcon from '@mui/icons-material/Forum';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import StarsIcon from '@mui/icons-material/Stars';

import discordIcon from "../assets/img/discord.png"

import LogoutIcon from "@mui/icons-material/Logout"


const drawerWidth = 220;

function ResponsiveDrawer() {
  const { course } = useParams();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [title, setTitle] = useState("");
  const navigate = useNavigate();

  const { state } = useContext(AppContext)
  const { username, isStaff } = state

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect( () =>{
    if(!course) {
      return
    }
    const parts = course.split("-");
    setTitle(`Curso ${parts[0].toUpperCase()}-${parts[1]}`)
    
  }, [course, title])

  const drawer = (
    <div>
      <Toolbar />
      <Divider />
      <List>
        <ListItemButton onClick={() =>  window.open("https://campus.inovecode.com")}>
          <ListItemIcon><AccountBalanceIcon /></ListItemIcon>
          <ListItemText primary={"Campus"} />
        </ListItemButton>
        <ListItemButton onClick={() => window.open(`https://admin.inovecode.com/perfil/chat/discord/link/?username=${username}`, "_blank")}>
          <ListItemIcon><img src={discordIcon} alt="discord"/></ListItemIcon>
          <ListItemText primary={"Discord"} />
        </ListItemButton>
        <ListItemButton onClick={() =>  window.open(`/cursos/${course}/unidades/0/link/clase`, "_blank")}>
          <ListItemIcon><PlayCircleIcon /></ListItemIcon>
          <ListItemText primary={"Clase en vivo"} />
        </ListItemButton>
      </List>
      <Divider />
      <List>
        <ListItemButton onClick={() =>  navigate(`/cursos/${course}/info`)}>
          <ListItemIcon><InfoIcon /></ListItemIcon>
          <ListItemText primary={"Info"} />
        </ListItemButton>
        <ListItemButton onClick={() =>  navigate(`/cursos/${course}/unidades`)}>
          <ListItemIcon><ImportContactsIcon /></ListItemIcon>
          <ListItemText primary={"Unidades"} />
        </ListItemButton>
        <ListItemButton onClick={() =>  navigate(`/cursos/${course}/devoluciones`)}>
          <ListItemIcon><ForumIcon /></ListItemIcon>
          <ListItemText primary={"Feedback"} />
        </ListItemButton>
        <ListItemButton onClick={() =>  navigate(`/cursos/${course}/calendario`)}>
          <ListItemIcon><CalendarMonthIcon /></ListItemIcon>
          <ListItemText primary={"Calendario"} />
        </ListItemButton>
      </List>
      <Divider />
      <List>
        <ListItemButton onClick={() =>  navigate(`/cursos/${course}/progreso`)}>
          <ListItemIcon><EmojiEventsIcon /></ListItemIcon>
          <ListItemText primary={"Logros"} />
        </ListItemButton>
        <ListItemButton onClick={() =>  navigate(`/cursos/${course}/ranking`)}>
          <ListItemIcon><StarsIcon /></ListItemIcon>
          <ListItemText primary={"Ranking"} />
        </ListItemButton>
      </List>
      <Divider />
      <List>
        {isStaff === true ?
          <ListItemButton onClick={() =>  navigate(`/cursos/${course}/metricas`)}>
            <ListItemIcon><InsertChartIcon /></ListItemIcon>
            <ListItemText primary={"Métricas"} />
          </ListItemButton>
          :
          null
        }
        <ListItemButton onClick={() =>  window.open("https://campus.inovecode.com/logout", "_blank")}>
          <ListItemIcon><LogoutIcon /></ListItemIcon>
          <ListItemText primary={"Logout"} />
        </ListItemButton>
      </List>
    </div>
  );

  return (
    <Box sx={{ display: "flex", height: "100%" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar
          sx={{backgroundColor:"linear-gradient(90deg, rgba(0,177,185,1) 0%, rgba(0,151,236,1) 100%);"}}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div" width="100%">
            <article className={styles.containerDrawer}>
              <div>
                <h3>{title}</h3>
              </div>
              <Avatar sx={{ bgcolor: "red" }} aria-label="recipe">
                {username? username[0].toUpperCase() : "."}
              </Avatar>
            </article>
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can
         be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
        <div className={styles.topTitle}>
          <Outlet />
        </div>
    </Box>
  );
}

export default ResponsiveDrawer;

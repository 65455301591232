import {useState, useEffect, useContext} from 'react'
import { useParams } from "react-router-dom"
import { AppContext } from "../appInfo";

import Layout from "../components/Layout"
import Header from "../components/Header"
import Unit from "../components/units/Unit"

import {StudenUnitAPI} from "../api/StudentUnitAPI"

function PantallaUnidades() {
  const { course } = useParams();
  const [units, setUnits] = useState("CARGANDO...")
  const { state } = useContext(AppContext)
  const { userview } = state

  useEffect( () =>{
    if(!userview) {
      return
    }
    StudenUnitAPI.getAll(userview, course).then((units) => {
      // response handling
      //console.log(units);
      setUnits(units);
    });
    return () => {
      //StudenUnitAPI.abort() // cancel request
    }
  }, [userview, course])

  return (
      <Layout 
        Header={<Header title={"Unidades"} />}
      >
        {Array.isArray(units)
        ?
          units.map( unit => (
            <Unit props={unit} key={unit.uid} />
          ))
          : "CARGANDO..."
        }
      </Layout>
  )
}

export default PantallaUnidades
import { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { AppContext } from "../appInfo";

import Layout from "../components/Layout"
import Header from "../components/Header"
import MiniCard from "../components/MiniCard"

import bookGold from "../assets/img/book_gold.svg"
import bookGray from "../assets/img/book_gray.svg"
import checkGreen from "../assets/img/check_green.png"
import checkBlack from "../assets/img/check_black.png"

import LinearProgress from "@mui/material/LinearProgress";
import Button from "@mui/material/Button";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import SchoolIcon from '@mui/icons-material/School';
import PersonPinIcon from '@mui/icons-material/PersonPin';

import {CourseAPI} from "../api/CourseAPI"


function PantallaInfo() {
  const { course } = useParams();

  const { state } = useContext(AppContext)
  const { username } = state

  const navigate = useNavigate();

  const [classesPassed, setClassesPassed] = useState(0)
  const [classesTotal, setClassesTotal] = useState(1)

  const [firstClassDatetime, setFirstClassDatetime] = useState("---")
  const [lastclassDatetime, setLastClassDatetime] = useState("---")

  const [courseCompletedUnits, setCourseCompletedUnits] = useState("---")
  const [courseNotCompletedUnits, setCourseNotCompletedUnits] = useState("---")
  const [courseCompletedChallenges, setCourseCompletedChallenges] = useState("---")
  const [courseNotCompletedChallenges, setCourseNotCompletedChallenges] = useState("---")

  const [staff, setStaff] = useState()

  useEffect( () =>{
    if(!username) {
      return
    }
    CourseAPI.info(username, course).then((data) => {
      console.log(data)
      setClassesPassed(data["classes_passed"])
      setClassesTotal(data["classes_total"])
      setFirstClassDatetime(data["first_class_datetime"])
      setLastClassDatetime(data["last_class_datetime"])
      setCourseCompletedUnits(data["ahieved_units"]);
      setCourseNotCompletedUnits(data["pending_units"] + data["future_units"])
      setCourseCompletedChallenges(data["ahieved_challenges"]);
      setCourseNotCompletedChallenges(data["pending_challenges"] + data["future_challenges"])
      setStaff(data["staff"])
    });
    return () => {
      //CourseAPI.abort() // cancel request
    }
  }, [username, course])

  const gridCards = {
    display: 'grid',
    gridTemplateColumns: "1fr 1fr",
    gridGap: '30px',
    width: "100%",
  }

  const flexItem = {
    width: "100%",
    display: "flex",
    justifyContent: "start",
    alignItems: "start",
  }

  const gridItem = {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "start",
  };

  const bookGoldIcon = <img src={bookGold} alt="mini" />
  const bookGrayIcon = <img src={bookGray} alt="mini" />
  const checkGreenIcon = <img src={checkGreen} alt="mini" />
  const checkBlackIcon = <img src={checkBlack} alt="mini" />

  return (
      <Layout 
        Header={<Header title={"Información"}/>}
      >
        <div style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          alignItems: "center",
          padding: "10px",
          gap: "20px",
        }}>

          <div style={flexItem}>
            <div style={{
              fontSize: "clamp(1rem, 3vw, 1.3rem)",
              fontWeight: "bold",
            }}
            >
              {`Info del curso ${course}`}
            </div>
          </div>

          <MiniCard
            sx={{ width: "clamp(350px, 100%, 400px)"}}
            icon={<SchoolIcon sx={{fontSize:"50px"}} />}
            value={`Clases transcurridas: ......... ${classesPassed} de ${classesTotal}`}
            text={<LinearProgress variant="determinate" value={100*(classesPassed/classesTotal)} />}
          />

          <div style={gridCards}>
            <div style={gridItem}><MiniCard icon={<CalendarMonthIcon sx={{fontSize:"30px"}} />} value={firstClassDatetime} text={"Primera clase"} /></div>
            <div style={gridItem}><MiniCard icon={<CalendarMonthIcon sx={{fontSize:"30px"}} />} value={lastclassDatetime} text={"Última clase"} /></div>
          </div>

          <Button
            size="medium"
            color="secondary"
            variant="outlined"
            onClick={() => navigate(`/cursos/${course}/calendario`)}
            >
              Ver el calendario completo
          </Button>

          <div style={flexItem}>
            <div style={{
              fontSize: "clamp(1rem, 3vw, 1.3rem)",
              fontWeight: "bold",
            }}
            >
              Info sobre tu avance
            </div>
          </div>


          <div style={gridCards}>
            <div style={gridItem}><MiniCard icon={bookGoldIcon} value={courseCompletedUnits} text={"Unidades completadas"} /></div>
            <div style={gridItem}><MiniCard icon={bookGrayIcon} value={courseNotCompletedUnits} text={"Unidades pendientes"} /></div>
            <div style={gridItem}><MiniCard icon={checkGreenIcon} value={courseCompletedChallenges} text={"Desafíos completados"} /></div>
            <div style={gridItem}><MiniCard icon={checkBlackIcon} value={courseNotCompletedChallenges} text={"Desafíos pendientes"} /></div>
          </div>

          <div style={flexItem}>
            <div style={{
              fontSize: "clamp(1rem, 3vw, 1.3rem)",
              fontWeight: "bold",
            }}
            >
              Equipo académico del curso
            </div>
          </div>

          {Array.isArray(staff)
            ?
            staff.map( (data, index) => (
              <MiniCard
                key={index}
                sx={{ width: "clamp(350px, 100%, 400px)" }}
                icon={<PersonPinIcon sx={{fontSize:"50px"}} />}
                value={data.name}
                text={data.role}
              />
            ))
            : null
          }

        </div>
      </Layout>
  )
}

export default PantallaInfo
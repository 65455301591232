import {useState} from 'react'
import classNames from 'classnames'

import styles from './DropDown.module.css'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/CloseOutlined';


function DropDown({children, title, status, onclick}) {
  const [active, setActive] = useState(false);

  const showContent = event => {
    // 👇️ toggle isActive state on click
    setActive(current => !current);
  };

  const handleClick = onclick? onclick : showContent;

  const containerClass = classNames({
    [styles.container]: true,
    [styles.active]: active,
    [styles.disabled]: status==="disabled",
    [styles.inProgress]: status==="inProgress",
    [styles.completed]: status==="completed",
    [styles.pass]: status==="pass",
    [styles.warning]: status==="warning",
    [styles.error]: status==="error",
  }); 

  return (
    <div className={containerClass}>
      <div className={styles.header}>
        <div className={styles.title}>
          {title}
        </div>
        <button className={styles.toggleBtn} onClick={handleClick}>
          <ExpandMoreIcon className={styles.fa_chevron_down} fontSize={'large'} />
          <CloseIcon className={styles.fa_times} fontSize={'large'} />
        </button>
      </div>
      <div className={styles.content}>        
        {children}
      </div>
    </div>
  )
}

export default DropDown
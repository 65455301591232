import {useState, useEffect, useContext} from 'react'
import { useParams } from 'react-router-dom';
import { AppContext } from "../../appInfo";
import fileDownload from 'js-file-download'

import DropDown from '../DropDown'

import {StudentChallengeSubmissionDownloadAPI} from "../../api/StudentChallengeSubmissionDownloadAPI"

import toast from 'react-hot-toast';

function ChallengeSubmission({cid, challengeCode, score, bonus, completed}) {
  const [scoreText, setScoreText] = useState("CARGANDO...")
  const [status, setStatus] = useState("disabled")

  const { course } = useParams();
  const { state, } = useContext(AppContext)
  const { userview, isStaff } = state

  useEffect( () =>{
    if(!userview) {
      return
    }

    let score_text = "<error>";
    if(score === "")
    {
      score_text = "SIN COMENZAR";
      setStatus("disabled");
    }
    else {
      setStatus("inProgress");
      if(score >= 0) {
        score_text = `Puntaje: ${score}`;
        if(bonus > 0) {
          score_text = `${score_text} (+${bonus} bonus)`
        }
        if(completed === true) {
          setStatus("completed");
        }
      }
      else {
        score_text = "SIN EVALUAR";
      }
    }
    setScoreText(score_text);
    return () => {
      //StudentChallengeSubmissionAPI.abort() // cancel request
    }
  }, [userview, score, bonus, completed])

  const download = () => {
    // if(isStaff !== true) {
    //   toast.error("Este botón está solo habilitado a los profes")
    //   return
    // }
    toast.success("Descargando entrega....", { icon: '🌠' });
    StudentChallengeSubmissionDownloadAPI.getAll(userview, course, cid).then((data) => {
      if(data["file_type"] !== "repository") {
        const filename = `${course}_${challengeCode}_${userview}.${data["file_extension"]}`;
        const filedata = data["solution"];
        fileDownload(filedata, filename)
      } else {
        window.open(data["solution"], "_blank")
      }
    });
  };

  return (
    <DropDown title={scoreText} status={status} onclick={() => download()} />
  )
}

export default ChallengeSubmission
import {useState, useEffect, useContext} from 'react'
import { useParams, useNavigate } from "react-router-dom";
import { AppContext } from "../appInfo";

import Layout from "../components/Layout"
import Header from "../components/Header"
import FeedbackRating from "../components/feedback/FeedbackRating"
import Congrats from "../components/Congrats"
import RestartQuestChip from '../components/units/RestartQuestChip';

import {StudentQuestMainChallengeAPI} from "../api/StudentQuestMainChallengeAPI"
import {StudentChallengeTestAPI} from "../api/StudentChallengeTestAPI"

import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Chip from '@mui/material/Chip';
import VisibilityIcon from '@mui/icons-material/Visibility';

import toast from 'react-hot-toast';



function PantallaEncuesta() {
  const { course, qid } = useParams();
  const [surveyID, setSurveyID] = useState(0)
  const [completedCount, setCompletedCount] = useState(0)
  const [questions, setQuestions] = useState([])
  const [answers, setAnswers] = useState({});
  const [errors, setErrors] = useState({});

  const [formDisplay, setFormDisplay] = useState("block")
  const [errorMessage, setErrorMessage] = useState()
  const [errorDetail, setErrorDetail] = useState()
  const [open, setOpen] = useState(false);
  const [congrats, setCongrats] = useState(null)

  const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
  };

  const { state } = useContext(AppContext)
  const { username, isStaff } = state

  const handleAnswer = (key, value, q) => {
    const aux = {...answers};
    aux[q.id][key] = value;
    setAnswers(aux)
  }

  useEffect( () =>{
    if(!username) {
      return
    }
    StudentQuestMainChallengeAPI.getAll(username, course, qid).then((data) => {
      //console.log(data)
      if(data['completed'] === true && isStaff === false) {
        navigate(`/cursos/${course}/unidades`)
      }
      setSurveyID(data["cid"])
      setCompletedCount(data["completed_count"])
      const newAnswers = data.questions.reduce((acc, q, index) => {
        // Aquí construimos el objeto JSON.
        // Usamos el índice de la pregunta como clave para cada pregunta
        acc[q.id] = {'value': null, 'question': q};
        return acc;
      }, {});
      setAnswers(newAnswers)
      setQuestions(data.questions);
    });
    return () => {
      //FeedbackListAPI.abort() // cancel request
    }
  }, [course, qid, username, navigate, isStaff])

  const handleSubmit = (e) => {
    e.preventDefault();

    const newErrors = {};
    for(const questionId in answers) {
      const data = answers[questionId];
      if(data.question.required) {
        if(data.question.type !== 3) {
          if(data.value === null) {
            newErrors[questionId] = true;
          }
        } else {
          for(const feedbackId in data.question.feedback) {
            const f = data.question.feedback[feedbackId]
            if(!data[`${f.username}.value`]) {
              newErrors[questionId] = true;
            }
          }
        }
      }
    }
    setErrors(newErrors);
    if (Object.keys(newErrors).length > 0) {
      toast.error("Debe responder todas las preguntas obligatorias");
      return;
    }

    toast.success("Enviando encuesta....", { icon: '🌠' });
    //console.log(answers)

    StudentChallengeTestAPI.test(username, course, surveyID, answers).then((data) => {
      toast.success("¡Encuesta completa!");
      setFormDisplay("block")
      if(data["coderun"]) {
        setFormDisplay("none")
        setCongrats(
          <Congrats
            message="¡Felicitaciones!"
            points={data["experience"]?.score || 0}
            bonusPoints={data["experience"]?.bonus || 0}
            extrapoints={data["experience"]?.quest_bonus || 0}
          />
        );

        setErrorMessage("");
        setErrorDetail("");
      }
      else {
        setErrorMessage(data["message"]);
        setErrorDetail(data["description"]);
        setOpen(true);
      }
    });

  };

  const questionContainerStyle = {paddingBottom:"20px"};

  const showResults = <Chip
      icon={<VisibilityIcon />}
      label={`${completedCount} result`}
      onClick={() => navigate(`${window.location.pathname}/resultados`)}
      color="primary"
    />

  return (
      <Layout 
        Header={
          <Header
            title={"Encuesta"}
            linkType={"back"}
            btn1={isStaff? showResults : null}
            btn2={isStaff? <RestartQuestChip qid={qid} /> : null}
          />}
      >
        <div style={{
          width: "100%",
          overflow:"visible",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "20px",
        }}
        >
          <Divider variant="middle" />
          <form onSubmit={handleSubmit} style={{width: "100%", display: formDisplay}}>
            <FormControl fullWidth>
              {questions.map((q, index) => {
                const isError = errors[q.id]; // Verifica si esta pregunta tiene un error
                if(q.type === 1) {
                  return (
                    <div key={`question-${q.id}`} style={questionContainerStyle}>
                      {isError && <FormHelperText error>Esta pregunta es obligatoria</FormHelperText>}
                      <FormLabel id="demo-row-radio-buttons-group-label">{q.required && <span style={{color: 'red'}}>*</span>}{q.statement}</FormLabel>
                      <RadioGroup
                        row
                        value={answers[q.id]?.value || ''}
                        onChange={(e) => handleAnswer('value', e.target.value, q)}
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                      >
                        {q.answers.map((a, index) => {
                          return <FormControlLabel key={`answer-${a.id}`} value={a.id} control={<Radio />} label={a.text} />
                        })}
                      </RadioGroup>
                    </div>
                  )
                }
                else if (q.type === 2) {
                  return (
                    <div key={`question-${q.id}`} style={questionContainerStyle}>
                      {isError && <FormHelperText error>Esta pregunta es obligatoria</FormHelperText>}
                      <FormLabel id="demo-row-radio-buttons-group-label">{q.required && <span style={{color: 'red'}}>*</span>}{q.statement}</FormLabel>
                      <TextField
                        multiline
                        fullWidth
                        placeholder={q.required? "(este campo es opcional)":""}
                        margin="normal"
                        rows={4}
                        value={answers[q.id]?.value || ''}
                        onChange={(e) => handleAnswer('value', e.target.value, q)}
                        inputProps={{ maxLength: 200 }}
                      />
                    </div>
                  )
                }
                else if (q.type === 3) {
                  return (
                    <div key={`question-${q.id}`} style={questionContainerStyle}>
                      {isError && <FormHelperText error>Esta pregunta es obligatoria</FormHelperText>}
                      {q.feedback.map((f, index) => {
                        return (
                          <div key={`encuesta-${f.username}`}>
                            <FormLabel id="demo-row-radio-buttons-group-label">{q.required && <span style={{color: 'red'}}>*</span>}{f.legend}</FormLabel>
                            <FeedbackRating
                              value={answers[q.id][`${f.username}.value`] || null}
                              disabled={false}
                              onChange={(value) => handleAnswer(`${f.username}.value`, value, q)}
                            />
                            <TextField
                                label={f.label}
                                placeholder={`(este campo es opcional)`}
                                multiline
                                fullWidth
                                margin="normal"
                                rows={4}
                                value={answers[q.id][`${f.username}.text`]}
                                onChange={(e) => handleAnswer(`${f.username}.text`, e.target.value, q)}
                                inputProps={{ maxLength: 300 }}
                            />
                          </div>
                        )
                      })}
                    </div>
                  )
                }
                else if (q.type === 4) {
                  //console.log(q)
                  return (
                    <div key={`question-${q.id}`} style={questionContainerStyle}>
                      {isError && <FormHelperText error>Esta pregunta es obligatoria</FormHelperText>}
                      {q.feedback.map((f, index) => {
                        return (
                          <div key={`course-${f.course}`}>
                            <FormLabel id="demo-row-radio-buttons-group-label">{q.required && <span style={{color: 'red'}}>*</span>}{f.legend}</FormLabel>
                            <FeedbackRating
                              value={answers[q.id]?.value || null}
                              disabled={false}
                              onChange={(value) => handleAnswer('value', value, q)}
                            />
                            <TextField
                                label={f.label}
                                placeholder={`(este campo es opcional)`}
                                multiline
                                fullWidth
                                margin="normal"
                                rows={4}
                                value={answers[q.id]?.text || ''}
                                onChange={(e) => handleAnswer('text', e.target.value, q)}
                                inputProps={{ maxLength: 300 }}
                            />
                          </div>
                        )
                      })}
                    </div>
                  )
                }
                else if (q.type === 5) {
                  return (
                    <div key={`question-${q.id}`} style={questionContainerStyle}>
                      {isError && <FormHelperText error>Esta pregunta es obligatoria</FormHelperText>}
                      {q.feedback.map((f, index) => {
                        return (
                          <div key={`platform-${f.course}`}>
                            <FormLabel id="demo-row-radio-buttons-group-label">{q.required && <span style={{color: 'red'}}>*</span>}{f.legend}</FormLabel>
                            <FeedbackRating
                              value={answers[q.id]?.value || null}
                              disabled={false}
                              onChange={(value) => handleAnswer('value', value, q)}
                            />
                            <TextField
                                label={f.label}
                                placeholder={`(este campo es opcional)`}
                                multiline
                                fullWidth
                                margin="normal"
                                rows={4}
                                value={answers[q.id]?.text || ''}
                                onChange={(e) => handleAnswer('text', e.target.value, q)}
                                inputProps={{ maxLength: 300 }}
                            />
                          </div>
                        )
                      })}
                    </div>
                  )
                }
                else {
                  return (<div key={`question-${q.id}`} style={questionContainerStyle}></div>)
                }
              })
              }

              <Button 
                variant="contained"
                color="success"
                size="large"
                type="submit"
              >
                PRESIONE PARA FINALIZAR
              </Button>
            </FormControl>
          </form>

          <div>
            {congrats}
          </div>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Error enviando la encuesta"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                  {errorMessage}
              </DialogContentText>
              <DialogContentText id="alert-dialog-description">
                  {errorDetail}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} autoFocus>CERRAR</Button>
            </DialogActions>
          </Dialog>
  
        </div>
      </Layout>
  )
}

export default PantallaEncuesta
import {useState, useEffect, useContext} from 'react'
import { useParams, useNavigate } from "react-router-dom";
import { AppContext } from "../appInfo";

import Layout from "../components/Layout"
import Header from "../components/Header"
import FeedbackRating from "../components/feedback/FeedbackRating"

import {SurveyChallengesAPI} from "../api/SurveyChallengesAPI"


import TextField from '@mui/material/TextField';


import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
ChartJS.register(ArcElement, Tooltip, Legend);

function getPieGraph(title, labels, data, index) {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: title,
      },
    },
  };

  const graphData = {
    labels,
    datasets: [
      {
        data: data,
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
      },
    ],
  };

  return <Pie options={options} data={graphData} key={index}/>
}

function PantallaEncuestaResultados() {
  const { course, qid } = useParams();
  const { state } = useContext(AppContext)
  const { username, isStaff } = state

  const [questions, setQuestions] = useState([])

  const navigate = useNavigate();

  // Mostrar esta panalla solo a staff
  useEffect( () =>{
    if(isStaff === false) {
      navigate(`/cursos/${course}/unidades`)
    }
  }, [course, navigate, isStaff])


  useEffect( () =>{
    if(!username) {
      return
    }
    SurveyChallengesAPI.getAll(username, course, qid).then((data) => {
      //console.log(data)
      setQuestions(data.questions);
    });
    return () => {
      //FeedbackListAPI.abort() // cancel request
    }
  }, [course, qid, username])

  
  const containerStyle = {width: "100%", display:"flex", flexDirection:"column", alignItems:"center"};
  const mainContainerStyle = {...containerStyle, gap:"30px"}
  const columnItemStyle = {width: "100%", maxWidth:"400px"};

  return (
      <Layout 
        Header={
          <Header
            title={"Encuesta: Respuestas"}
            linkType={"back"}
          />}
      >
        <div style={{
          width: "100%",
          overflow:"visible",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "20px",
        }}
        >
        <div style={mainContainerStyle}>
        {questions.map((q, index) => {
            if(q.type === 1) {
              return (
                <div key={`question-${q.id}`} style={columnItemStyle}>
                  {getPieGraph(q.statement, q.choice_text, q.choice_count, index)}
                </div>
              )
            }
            else if (q.type === 2) {
              return (
                <div key={`question-${q.id}`} style={containerStyle}>
                {q.values.map((value, index) => {
                  return (
                    <div key={`question-${q.id}-value-${index}`} style={columnItemStyle}>
                      {value}
                    </div>
                  )
                })}
                </div>
              )
            }
            else if (q.type === 3) {
              return (
                <div key={`question-${q.id}`} style={containerStyle}>
                {q.feedbacks.map((feedback, index) => {
                  return (
                    <div key={`question-${q.id}-feedbacks-${index}`} style={columnItemStyle}>
                      <span>{feedback.legend}</span>
                      <FeedbackRating
                        value={feedback.value}
                        disabled={true}
                      />
                      <TextField
                          multiline
                          fullWidth
                          margin="normal"
                          rows={4}
                          value={feedback.text}
                          disabled={true}
                      />
                    </div>
                  )
                })}
                </div>
              )
            }
            else if (q.type === 4) {
              return (
                <div key={`question-${q.id}`} style={containerStyle}>
                {q.course.map((feedback, index) => {
                  return (
                    <div key={`question-${q.id}-course-${index}`} style={columnItemStyle}>
                      <span>{feedback.legend}</span>
                      <FeedbackRating
                        value={feedback.value}
                        disabled={true}
                      />
                      <TextField
                          multiline
                          fullWidth
                          margin="normal"
                          rows={4}
                          value={feedback.text}
                          disabled={true}
                      />
                    </div>
                  )
                })}
                </div>
              )
            }
            else if (q.type === 5) {
              return (
                <div key={`question-${q.id}`} style={containerStyle}>
                {q.platform.map((feedback, index) => {
                  return (
                    <div key={`question-${q.id}-platform-${index}`} style={columnItemStyle}>
                      <span>{feedback.legend}</span>
                      <FeedbackRating
                        value={feedback.value}
                        disabled={true}
                      />
                      <TextField
                          multiline
                          fullWidth
                          margin="normal"
                          rows={4}
                          value={feedback.text}
                          disabled={true}
                      />
                    </div>
                  )
                })}
                </div>
              )
            }
            else {
              return (<div key={`question-${q.id}`} style={columnItemStyle}></div>)
            }
          }
        )}
        </div>

        </div>
      </Layout>
  )
}

export default PantallaEncuestaResultados
import { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { AppContext } from "../appInfo";

import Layout from "../components/Layout"
import Header from "../components/Header"
import NavBar from "../components/NavBar"

import explevel from "../assets/img/xp.png"
import medal from "../assets/img/medal.png"
import fire from "../assets/img/fire.png"
import bookGold from "../assets/img/book_gold.svg"
import bookGray from "../assets/img/book_gray.svg"
import checkGreen from "../assets/img/check_green.png"
import checkBlack from "../assets/img/check_black.png"

import MiniCard from "../components/MiniCard"

import LinearProgress from '@mui/material/LinearProgress';

import {StudentProgressAPI} from "../api/StudentProgressAPI"

function PantallaProgreso() {
  const { course } = useParams();

  const { state } = useContext(AppContext)
  const { username, isStaff } = state

  const navigate = useNavigate();

  const [levelNumber, setLevelNumber] = useState("---")
  const [levelProgress, setLevelProgress] = useState(0)

  const [streak, setStreak] = useState("---")
  const [experience, setExperience] = useState("---")
  const [completedUnits, setCompletedUnits] = useState("---")
  const [notCompletedUnits, setNotCompletedUnits] = useState("---")

  const [courseCompletedUnits, setCourseCompletedUnits] = useState("---")
  const [courseNotCompletedUnits, setCourseNotCompletedUnits] = useState("---")
  const [courseCompletedChallenges, setCourseCompletedChallenges] = useState("---")
  const [courseNotCompletedChallenges, setCourseNotCompletedChallenges] = useState("---")

  useEffect( () =>{
    if(!username) {
      return
    }
    StudentProgressAPI.getAll(username, course).then((data) => {
      setLevelNumber(data["level_number"]);
      setLevelProgress(data["level_progress"]);
      setStreak(data["current_streak"]);
      setExperience(data["total_experience"]);
      setCompletedUnits(data["ahieved_units"]);
      setNotCompletedUnits(data["pending_units"] + data["future_units"])
    });
    StudentProgressAPI.get(username, course).then((data) => {
      setCourseCompletedUnits(data["ahieved_units"]);
      setCourseNotCompletedUnits(data["pending_units"] + data["future_units"])
      setCourseCompletedChallenges(data["ahieved_challenges"]);
      setCourseNotCompletedChallenges(data["pending_challenges"] + data["future_challenges"])
    });
    return () => {
      //StudentProgressAPI.abort() // cancel request
    }
  }, [username, course])

  useEffect( () =>{
    if(!username) {
      return
    }

  }, [course, username])

  const gridCards = {
    display: 'grid',
    gridTemplateColumns: "1fr 1fr",
    gridGap: '30px',
    width: "100%",
  }

  const flexItem = {
      width: "100%",
      display: "flex",
      justifyContent: "start",
      alignItems: "start",
  }
  const gridItem = {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "start",
  }

  const explevelIcon = <img src={explevel} alt="mini" />
  const fireIcon = <img src={fire} alt="mini" />
  const medalIcon = <img src={medal} alt="mini" />
  const bookGoldIcon = <img src={bookGold} alt="mini" />
  const bookGrayIcon = <img src={bookGray} alt="mini" />
  const checkGreenIcon = <img src={checkGreen} alt="mini" />
  const checkBlackIcon = <img src={checkBlack} alt="mini" />

  return (
      <Layout 
        Header={<Header title={"Estadística general"}/>}
      >

        {`Progreso de ${username}`}

        <MiniCard
          sx={{ width: "clamp(350px, 100%, 400px)"}}
          icon={explevelIcon}
          value={`Nivel: ${levelNumber} - Exp: ${experience}`}
          text={<LinearProgress variant="determinate" value={levelProgress} />}
        />

        <div style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          alignItems: "center",
          padding: "10px",
          gap: "10px",
        }}>
          <div style={flexItem}>
            <div style={{
              fontSize: "clamp(1rem, 3vw, 1.3rem)",
              fontWeight: "bold",
            }}
            >Estadística general
            </div>
          </div>
          <div style={gridCards}>
            <div style={gridItem}><MiniCard icon={fireIcon} value={streak} text={"Semanas racha"} /></div>
            <div style={gridItem}><MiniCard icon={medalIcon} value={experience} text={"Experiencia"} /></div>
            <div style={gridItem}><MiniCard icon={bookGoldIcon} value={completedUnits} text={"Unidades completadas"} /></div>
            <div style={gridItem}><MiniCard icon={bookGrayIcon} value={notCompletedUnits} text={"Unidades pendientes"} /></div>
          </div>

          <div style={flexItem}>
            <div style={{
              fontSize: "clamp(1rem, 3vw, 1.3rem)",
              fontWeight: "bold",
            }}
            >Estadística del curso
            </div>
          </div>
          <div style={gridCards}>
            <div style={gridItem}><MiniCard icon={bookGoldIcon} value={courseCompletedUnits} text={"Unidades completadas"} /></div>
            <div style={gridItem}><MiniCard icon={bookGrayIcon} value={courseNotCompletedUnits} text={"Unidades pendientes"} /></div>
            <div style={gridItem}><MiniCard icon={checkGreenIcon} value={courseCompletedChallenges} text={"Desafíos completados"} /></div>
            <div style={gridItem}><MiniCard icon={checkBlackIcon} value={courseNotCompletedChallenges} text={"Desafíos pendientes"} /></div>
          </div>

        </div>
      </Layout>
  )
}

export default PantallaProgreso
import React from 'react';

import styles from './Layout.module.css'

import { Toaster } from "react-hot-toast"

function Layout({ children, Header, Footer }) {
  const mobile = true;
  return (
    <div className={styles.layoutContainer}>
      {mobile
        ?
        <>
          {Header}
          <div className={styles.pageContainer}>
            { children }
          </div>
          {Footer}
        </>
        :
        <>
          {Header}
          <div className={styles.pageContainer}>
            { children }
          </div>
        </>
      }
      <Toaster 
        containerStyle={{
          top: '90px'
        }}
        />
    </div>
  )
}

export default Layout
import { useContext } from 'react'
import { useParams } from 'react-router-dom';
import { AppContext } from "../../appInfo";
import fileDownload from 'js-file-download'

import Button from '@mui/material/Button';

import toast from 'react-hot-toast';

import {StudentChallengeProblemAPI} from "../../api/StudentChallengeProblemAPI"

function ChallengeProblem({cid}) {
  const { course } = useParams();

  const { state } = useContext(AppContext)
  const { username } = state

  const downloadChallenge = () => {
    if(!username) {
      return
    }
    StudentChallengeProblemAPI.getAll(username, course, cid).then((data) => {
      if(data["name"]) {
        // Si el response fue válido
        if(data["file_type"] !== "repository") {
          // 1 - Realizar la descarga del problema
          const filename = `${course}_${data["challenge_code"]}.${data["file_extension"]}`;
          const filedata = data["problem"];
          fileDownload(filedata, filename)
        } else {
          window.open(data["problem"], "_blank")
        }
      } else {
        toast.error("No se pudo descargar el desafío");
      }
    });
  }

  return (
    <Button 
      className={"swiper-no-swiping"}
      variant="contained"
      color="success"
      size="large"
      onClick={downloadChallenge}
    >
      DESCARGAR DESAFIO
    </Button>
  )
}

export default ChallengeProblem
import { useContext } from 'react'
import { useParams } from "react-router-dom";
import { AppContext } from "../../appInfo";

import Chip from '@mui/material/Chip';
import DeleteIcon from '@mui/icons-material/Delete';

import toast from 'react-hot-toast';

import {StudentQuestChallengesAPI} from "../../api/StudentQuestChallengesAPI"

function RestartQuestChip({qid}) {
  const { course } = useParams();
  const { state } = useContext(AppContext)
  const { username } = state

  const handleClick = () => {
    toast.success("Reiniciando nivel....", { icon: '🌠' });
    StudentQuestChallengesAPI.restart(username, course, qid).then((data) => {
      if(data["status"]) {
        window.location.reload();
      }
      else {
        toast.error("No fue posible reiniciar este nivel");
      }

    });
  }

  return (
      <Chip
      icon={<DeleteIcon />}
      label={"Reiniciar"}
      onClick={() => handleClick()}
      color="secondary"
    />
  )
}

export default RestartQuestChip
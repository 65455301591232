import React from "react";
import pdf from "../assets/img/pdf.png"
import ppt from "../assets/img/ppt.png"
import git from "../assets/img/github.png"
import video from "../assets/img/video.png"

const IconDic = ({ sx, type }) => {
    const renderContent = () => {
        switch (type) {
            case "pdf":
                return (
                    <img
                        src={pdf}
                        alt="pregunta"
                        style={sx}
                    />
                );
            case "ppt":
                return (
                    <img
                        src={ppt}
                        alt="codigo"
                        style={sx}
                    />
                );
            case "github":
                return (
                    <img
                        src={git}
                        alt="tutoriales"
                        style={sx}
                    />
                );
            case "video":
                return (
                    <img
                        src={video}
                        alt="python"
                        style={sx}
                    />
                );
            default:
                return null;
        }
    };

    return renderContent();
};

export default IconDic;

// version.js
const APP_VERSION = '2024.04.16';

const checkVersionAndUpdateCache = () => {
  const cachedVersion = localStorage.getItem('appInoveVersion');
  if (cachedVersion !== APP_VERSION) {
    // Si existe una versión anterior y no coincide, limpiar el caché
    console.log(`Nueva versión detectada ${APP_VERSION}, limpiando caché ${cachedVersion}...`);
    caches.keys().then(function(names) {
        for (let name of names) caches.delete(name);
    });
    localStorage.setItem('appInoveVersion', APP_VERSION);
    window.location.reload(true); // Forzar recarga para cargar los nuevos archivos
  } else {
    console.log(`Versión detectada ${cachedVersion}`);
  }
}

export default checkVersionAndUpdateCache;
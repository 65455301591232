import {useState, useEffect, useContext} from 'react'
import { useParams } from "react-router-dom";
import { AppContext } from "../appInfo";

import Layout from "../components/Layout"
import Header from "../components/Header"
import TextBox from "../components/TextBox"
import FeedbackCard from "../components/feedback/FeedbackCard"
import FeedbackRating from "../components/feedback/FeedbackRating"

import {FeedbackDetailAPI} from "../api/FeedbackDetailAPI"
import {FeedbackCompletedAPI} from "../api/FeedbackCompletedAPI"

import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';

import toast from 'react-hot-toast';


function PantallaDevolucionAlumno() {
  const { course, fid } = useParams();
  const [feedbackCard, setFeedbackCard] = useState("CARGANDO...")
  const [feedbackText, setFeedbackText] = useState("CARGANDO...")
  const [rating, setRating] = useState(0)
  const [ratingText, setRatingText] = useState("")
  const [feedbackCompleted, setFeedbackCompleted] = useState(false)
  
  const { state } = useContext(AppContext)
  const { username } = state

  const handlerSetFeedback = (feedback) => {
    return <FeedbackCard
            fid={null} // Para que no haga el navigateTo
            title={feedback.title}
            completed={feedback.completed}
            points={feedback.points}
            rating={feedback.rating_score}
            user={feedback.user}
            date={feedback.date}
            userview={feedback.userview}
          />
  }

  useEffect( () =>{
    if(!username) {
      return
    }
    FeedbackDetailAPI.getAll(username, course, fid).then((feedback) => {
      //console.log(feedback)
      setFeedbackText(feedback.feedback)
      setFeedbackCompleted(feedback.completed)
      setRating(feedback.rating_score)
      setRatingText(feedback.rating_text)
      setFeedbackCard(handlerSetFeedback(feedback))
    });
    return () => {
      //FeedbackListAPI.abort() // cancel request
    }
  }, [course, fid, username])

  const handleSubmit = (e) => {
    e.preventDefault();

    if(rating === 0) {
      toast.error("Debe calificar la que obtuvo devolución");
      return;
    }
    
    let apiMethod = FeedbackCompletedAPI.create;
    if(feedbackCompleted === true) {
      apiMethod = FeedbackCompletedAPI.update;
    }

    const feedback_raiting = {
      rating: rating,
      ratingText: ratingText,
      feedback_link: `${window.location.protocol}//${window.location.hostname}/cursos/${course}/devoluciones/profesor/${fid}`
    }
    console.log(feedback_raiting)
    toast.success("Enviando calificación....", { icon: '🌠' });
    apiMethod(username, course, fid, feedback_raiting).then((feedback) => {
      setFeedbackCard(handlerSetFeedback(feedback))
      toast.success("¡Devolución completa!");
    });
    return () => {
      //FeedbacksQuestListAPI.abort() // cancel request
    }
  };

  return (
      <Layout 
        Header={<Header linkType={"back"}/>} 
      >
        <div style={{
          width: "100%",
          overflow:"visible",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "20px",
        }}
        >
          {feedbackCard}
          <TextBox>
            {["Devolución:", feedbackText]}
          </TextBox>
          <Divider variant="middle" />
          <form onSubmit={handleSubmit} style={{width: "100%"}}>
            <FormControl fullWidth>
              <FormLabel id="demo-row-radio-buttons-group-label">Califique su devolución</FormLabel>
              <TextField
                id="raiting-text"
                label="Comentario adicional (opcional):"
                placeholder={`Puede dejar un comentario opcional sobre su devolución`}
                multiline
                fullWidth
                margin="normal"
                rows={4}
                value={ratingText}
                onChange={(e) => setRatingText(e.target.value)}
                inputProps={{ maxLength: 150 }}
              />
              <FeedbackRating 
                value={rating} 
                disabled={false}
                onChange={(value) => setRating(value)}
              />
              <Button 
                variant="contained"
                color="success"
                size="large"
                type="submit"
              >
                {feedbackCompleted? "ACTUALIZAR DEVOLUCIÓN" : "COMPLETAR DEVOLUCIÓN"}
              </Button>
            </FormControl>
          </form>
  
        </div>
      </Layout>
  )
}

export default PantallaDevolucionAlumno
import { api } from "./client"

const controller = new AbortController();

export const CourseMetricsAPI = {
  get: async function (username, course) {
    const response = await api.request({
      url: `/student/${username}/course/${course}/metrics/course/`,
      method: "GET",
      signal: controller.signal
    })
    if(response) {
      return response.data
    }
  },
  ranking: async function (username, course) {
    const response = await api.request({
      url: `/student/${username}/course/${course}/metrics/course/ranking/`,
      method: "GET",
      signal: controller.signal
    })
    if(response) {
      return response.data
    }
  },
  abort: function(){
    controller.abort()
  },
}
import {useState, useEffect, useContext} from 'react'
import { useParams, useNavigate } from "react-router-dom";
import { AppContext } from "../../appInfo";

import IconTextButton from '../IconTextButton';

import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';

import toast from 'react-hot-toast';

import { FeedbackRequestAPI } from "../../api/FeedbackRequestAPI"

function FeedbackButton({props, status, setStatus, challengesVisible}) {
  const { course } = useParams();
  const { state } = useContext(AppContext)
  const { username } = state
  const navigate = useNavigate();

  const [component, setComponent] = useState(<></>)

  useEffect( () =>{
    let callback = (e) => {
      e.preventDefault();
      toast.error("¡Aún no alcanzaste la meta!");
    }
    let disabled = false;
    if(!challengesVisible) {
      // Los desafíos aún no están habilitados
      disabled = true;
      callback = (e) => {
        e.preventDefault();
        toast.error("Los Desafíos de está unidad aún no están habilitados");
      }
    }
    if(status === 2) {
      // Se puede pedir la devolucion
      callback = (e) => {
        e.preventDefault();
        toast.success("Enviando solicitud..." , { icon: '🌠' });
        FeedbackRequestAPI.getAll(username, course, props.fid).then((feedback) => {
          toast.success("¡Feedback solicitado!");
          setStatus(3)
        }).catch( error => {
          console.log(error);
          if (error.response) {
            // La solicitud fue realizada y el servidor respondió
            // con un código de estado que no está en el rango 2xx
            if (error.response.status === 400) {
              toast.error("Aún tiene devoluciones sin calificar. Califique sus devoluciones para poder solicitar una nueva.");
            } else {
              toast.error("Ocurrio un error al solicitar el feedback");
            }
          } else {
            toast.error("Ocurrio un error al solicitar el feedback");
          }
        });
        return () => {
          //StudenUnitAPI.abort() // cancel request
        }
        
      }
    }
    else if(status === 3) {
      // Devolución pendiente
      callback = (e) => {
        e.preventDefault();
        toast.success("El profe ya está al tanto de tu trabajo");
      }
    }
    else if(status === 4) {
      // Devolución recibida
      callback = (e) => {
        e.preventDefault();
        navigate(`/cursos/${course}/devoluciones/alumno/${props.fid}`)
      }
    }
    else if(status === 5) {
      // Devolución completa
      callback = (e) => {
        e.preventDefault();
        navigate(`/cursos/${course}/devoluciones/alumno/${props.fid}`)
      }
    }
    else if(status === 6) {
      // Devolución vencida
      disabled = true
      callback = (e) => {
        e.preventDefault();
        toast.error("Ya no puede solicitar feedback");
      }
    }

    setComponent(
      <IconTextButton 
        icon={<QuestionAnswerIcon sx={{ fontSize: 40 }}/>}
        text={"Pedir feedback"}
        disabled={disabled}
        onClick={(e) => callback(e)}
      />
    )

  }, [props, status, course, username, challengesVisible, navigate])


  return (
   <>{component}</>
  )
}

export default FeedbackButton
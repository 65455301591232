import { useNavigate } from "react-router-dom";
import { useEffect, useContext } from "react";
import { AppContext } from "../appInfo";

import Layout from "../components/Layout"
import Header from "../components/Header"

function PantallaHome() {
  const {state} = useContext(AppContext)
  const {course, username,  isStaff} = state

  const navigate = useNavigate();
  
  useEffect( () =>{
    if(course && username) {
      navigate(`/cursos/${course}/unidades`)
    }
  }, [username, course, navigate])

  return (
      <Layout 
        Header={<Header />} 
      >
        <div>
          <div>Curso: {course? course: "ERROR"}</div>
          <div>Usuario: {username? username: "ERROR"}</div>
          <div>Staff: {isStaff? "SI": "NO"}</div>
          <div>Si el usuario o curso figuran con error, debe volver a ingresar al sistema desde el campus</div>
        </div>
      </Layout>
  )
}

export default PantallaHome
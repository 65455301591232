import {useState, useRef, useContext} from 'react'
import { useParams } from 'react-router-dom';
import { AppContext } from "../../appInfo";
import classNames from 'classnames'

import styles from './ChallengeTest.module.css'

import Congrats from "../Congrats"
import ChallengeTestCodeScript from "./tests/ChallengeTestCodeScript"

import {StudentChallengeTestAPI} from "../../api/StudentChallengeTestAPI"

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import toast from 'react-hot-toast';


function ChallengeUpload({cid, isRepository, setScore, setBonus, setCompleted}) {
  const [scoreText, setScoreText] = useState()
  const [errorMessage, setErrorMessage] = useState()
  const [errorDetail, setErrorDetail] = useState()
  const [tests, setTests] = useState()
  const [resultHide, setResultHide] = useState(true);
  const [congrats, setCongrats] = useState(null)

  const { course } = useParams();
  const { state } = useContext(AppContext)
  const { username } = state

  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.value = null;
    fileInputRef.current.click();
  };

  const submitSolution = (solution) => {
    setResultHide(true);
    toast.success("Enviando solución....", { icon: '🌠' });
    StudentChallengeTestAPI.test(username, course, cid, solution).then((data) => {

      setScoreText(`Puntaje: ${data["score"]}`);
      setResultHide(false);
      setCongrats(null);

      if(data["coderun"]) {
        if(data["tests"]) {
          setTests(data["tests"]);
        }
        else {
          setTests([]);
        }
        setScore(data["score"])
        setBonus(data["bonus"])
        if(data["score"] === 100){
          setCompleted(true)
        }

        if(data["experience"]) {
          setCongrats(
            <Congrats
              message="¡Felicitaciones!" 
              points={data["experience"]["score"]}
              bonusPoints={data["experience"]["bonus"]}
              extrapoints={data["experience"]["quest_bonus"]}
            />
          );
          
        }

        setErrorMessage("");
        setErrorDetail("");
      } 
      else {
        setTests([]);
        setErrorMessage(data["message"]);
        setErrorDetail(data["description"]);
      }
      
    }).catch((error) => {
      console.error('Error al enviar la solución:', error);
      toast.error('Error al enviar la solución. Intente nuevamente.');
    });
  }

  function readFileAsText(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = () => reject(reader.error);
      reader.readAsText(file);
    });
  }

  const handleFileUpload = (event) => {
    event.preventDefault();
    const file = event.target.files[0];

    if (!file) {
      toast.error('Error al subir el archivo. Por favor, intente subirlo nuevamente.');
      return;
    }

    if (file.size > 1000000) {
      toast.error("El archivo supera los 1MB de tamaño máximo permitido");
      return;
    }

    // if (!(file.type === "text/x-python" || file.type === "text/plain")) {
    //   toast.error("Formato inválido, solo se permite archivo python (.py) o de texto (.txt)");
    //   return;
    // }

    readFileAsText(file)
      .then(submitSolution)
      .catch(error => {
        console.error('Error al procesar el archivo:', error);
        toast.error('Error al leer el archivo. Por favor, intente nuevamente.');
      })
      .finally(() => {
        // Limpiar el input para permitir la misma selección de archivo nuevamente
        event.target.value = '';
      });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const solution = e.target.url.value;
    if(solution === "") {
      toast.error("Debe especificar el link de su repositorio");
      return;
    }
    submitSolution(solution);
  };

  const resultSessionClass = classNames({
    [styles.section]: true,
    [styles.hide]: resultHide,
  }); 

  return (
    <>
    <div className={styles.section}>
        {
          isRepository === false?
          <div style={{width:"100%"}}>
          <h3>Presiona el botón para subir y entregar tu desafío</h3>
          <input
            type="file"
            id="archivoInput"
            onChange={handleFileUpload}
            ref={fileInputRef}
            style={{ display: "none" }}
          />
          <Button
            variant="contained"
            color="success"
            size="large"
            sx={{width:"100%"}}
            onClick={handleButtonClick}
          >
            SUBIR DESAFIO
          </Button>
          </div>
          :
          <>
          <h3>Indicanos el link de tu repositorio y aprieta el botón</h3>
          <form onSubmit={handleFormSubmit} style={{width: "100%"}}>
            <TextField
              id="url"
              type={"url"}
              placeholder={`Link de su repositorio`}
              fullWidth
              margin="normal"
            />
            <Button 
              variant="contained"
              color="success"
              size="large"
              type="submit"
            >
              Enviar repositorio
            </Button>
          </form>
          </>
        }
        {/* <p>Procesando...</p> */}
    </div>
    <section className={`${resultSessionClass} swiper-no-swiping`}>
      <h3>{scoreText}</h3>
      <div>{errorMessage}</div>
      <div style={{textAlign: "start"}}><pre>{errorDetail}</pre></div>
    </section>
    
    <section className={`${resultSessionClass} ${styles.testCardSection} ${"swiper-no-swiping"}`} >
      {congrats}
      <ChallengeTestCodeScript>
        {tests}
      </ChallengeTestCodeScript>
    </section>
    </>
  )
}

export default ChallengeUpload
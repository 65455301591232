import { api } from "./client"

const controller = new AbortController();

export const RecursosAPI = {
  getAll: async function (username, course, idx) {
    
    const response = await api.request({
      url: `/student/${username}/course/${course}/units/${idx}/resources/`,
      method: "GET",
      signal: controller.signal
    })
    if(response) {
      return response.data
    }
  },
  getByID: async function (username, course, rid) {
    
    const response = await api.request({
      url: `/student/${username}/course/${course}/resources/${rid}/`,
      method: "GET",
      signal: controller.signal
    })
    if(response) {
      return response.data
    }
  },
  getByType: async function (username, course, uidx, ltype) {
      const response = await api.request({
        url: `/student/${username}/course/${course}/units/${uidx}/link/${ltype}/`,
        method: "GET",
        signal: controller.signal
      })
      if(response) {
        return response.data
      }
  },
  abort: function(){
    controller.abort()
  },
}
import {useState, useContext} from 'react'
import { useParams } from 'react-router-dom';
import { AppContext } from "../../appInfo";
import classNames from 'classnames'

import styles from './QuestionnaireTest.module.css'

import Congrats from "../Congrats"
import DropDown from '../DropDown'

import {StudentChallengeTestAPI} from "../../api/StudentChallengeTestAPI"

import Button from '@mui/material/Button';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';

import toast from 'react-hot-toast';


function QuestionnaireTest({cid, choices}) {
  const [score, setScore] = useState()
  const [results, setResults] = useState([])
  const [resultHide, setResultHide] = useState(true);
  const [allPass, setAllPass] = useState(false);
  const [congrats, setCongrats] = useState(null)

  const { course } = useParams();
  const { state } = useContext(AppContext)
  const { username } = state

  const restart = () => {
    window.location.reload();
  }

  const submitSolution = () => {
    if(choices.filter(obj => obj === null).length) {
      toast.error("Debe contestar todas las preguntas");
      return 
    }
    console.log(choices)
    setResultHide(true);
    setAllPass(false);
    toast.success("Enviando solución....", { icon: '🌠' });
    StudentChallengeTestAPI.test(username, course, cid, choices).then((data) => {
      setScore(`Puntaje: ${data["score"]}`);
      setResultHide(false);

      if(data["coderun"]) {
        if(data["tests"]) {
          setResults(data["tests"]);
        }
        else {
          setResults([]);
        }
        if(data["score"] === 100){
          setAllPass(true);
        }

        if(data["experience"]) {
          setCongrats(
            <Congrats
              message="¡Felicitaciones!" 
              points={data["experience"]["score"]}
              bonusPoints={data["experience"]["bonus"]}
              extrapoints={data["experience"]["quest_bonus"]}
            />
          );
          
        }

      } 
      else {
        setResults([]);
      }
      
    });
  }

  
  const infoSessionClass = classNames({
    [styles.section]: true,
    [styles.hide]: !resultHide,
  });
  const resultSessionClass = classNames({
    [styles.section]: true,
    [styles.hide]: resultHide,
  });

  return (
    <>
    <section className={`${infoSessionClass} swiper-no-swiping`}>
      <h3>Preguntas contestadas: {choices.filter(obj => obj !== null).length}/{choices.length} </h3>
      <Button 
        variant="contained"
        color="success"
        size="large"
        type="submit"
        onClick={() => submitSolution()}
        >
        PRESIONE PARA FINALIZAR
      </Button>
    </section>
    <section className={`${resultSessionClass} swiper-no-swiping`}>
      <h3>{score}</h3>
      {
        !allPass? 
          <Button 
          variant="contained"
          color="success"
          size="large"
          type="submit"
          onClick={() => restart()}
          >
            VOLVER A INTENTAR
          </Button>
          :
          null
      }
    </section>
    
    <section className={`${resultSessionClass} ${styles.resultSection} ${"swiper-no-swiping"}`} >
      {congrats}
      <div className={styles.resultList}>
        {
          results?.map((result, index) => {
            return (
            <DropDown
              key={index}
              title={<div className={styles.resultTitle}><TrackChangesIcon fontSize={'large'} />Pregunta {index+1}: {result.passed? "correcta": "incorrecta"}</div>}
              status={result.passed? "pass":"error"}
            >
              {result.description}
            </DropDown>
            )
          })
        }
        
      </div>
    </section>
    </>
  )
}

export default QuestionnaireTest
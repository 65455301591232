import { useContext } from 'react'
import { useParams, useNavigate } from "react-router-dom";
import { AppContext } from "../../appInfo";

import styles from './Quest.module.css'

import QuestBillboard from "./QuestBillboard"
import QuestFinishLine from "./QuestFinishLine"

// Icons
import bookDisabled from "../../assets/img/book_gray.svg"
import bookInprogress from "../../assets/img/book_green.svg"
import bookCompleted from "../../assets/img/book_gold.svg"
import oneDisabled from "../../assets/img/one_gray.svg"
import oneInprogress from "../../assets/img/one_green.svg"
import oneCompleted from "../../assets/img/one_gold.svg"
import twoDisabled from "../../assets/img/two_gray.svg"
import twoInprogress from "../../assets/img/two_green.svg"
import twoCompleted from "../../assets/img/two_gold.svg"
import threeDisabled from "../../assets/img/three_gray.svg"
import threeInprogress from "../../assets/img/three_green.svg"
import threeCompleted from "../../assets/img/three_gold.svg"
import fourDisabled from "../../assets/img/four_gray.svg"
import fourInprogress from "../../assets/img/four_green.svg"
import fourCompleted from "../../assets/img/four_gold.svg"

import toast from 'react-hot-toast';

import Tooltip from '@mui/material/Tooltip';
import HowToVoteIcon from '@mui/icons-material/HowToVote';

function getQuestIcon(status, type, level) {
  let src= "";
  if(type === "TriviaQuest") {
    switch (status){
      case 'disabled':  src=bookDisabled;break;
      case 'inProgress': src=bookInprogress;break;
      case 'completed': src=bookCompleted;break;
      default: break;
    }
  }
  if(type === "Q&AQuest") {
    switch (status){
      case 'disabled':  src=bookDisabled;break;
      case 'inProgress':  src=bookInprogress;break;
      case 'completed': src=bookCompleted;break;
      default: break;
    }
  }
  if(type === "SurveyQuest") {
    let color = "rgb(175,175,175)";
    switch (status){
      case 'disabled':  color="rgb(175,175,175)";break;
      case 'inProgress':  color="rgb(23,80,0)";break;
      case 'completed': color="rgb(219,135,0)";break;
      default: break;
    }
    return <HowToVoteIcon sx={{
      height:"38px",
      left:"14px",
      position:"absolute",
      top:"8px",
      width:"42px",
      color: color,
    }}/>
  }
  if(type === "CodeQuest" || type === "ProyectoQuest") {
    switch (level){ 
      case 1:
          switch (status){
            case 'disabled':  src=oneDisabled;break;
            case 'inProgress':  src=oneInprogress;break;
            case 'completed': src=oneCompleted;break;
            default: break;
          }
          break;
      case 2:
          switch (status){
            case 'disabled':  src=twoDisabled;break;
            case 'inProgress':  src=twoInprogress;break;
            case 'completed': src=twoCompleted;break;
            default: break;
          }
          break;
      case 3:
          switch (status){
            case 'disabled':  src=threeDisabled;break;
            case 'inProgress':  src=threeInprogress;break;
            case 'completed': src=threeCompleted;break;
            default: break;
          }
          break;
      case 4:
          switch (status){
            case 'disabled':  src=fourDisabled;break;
            case 'inProgress':  src=fourInprogress;break;
            case 'completed': src=fourCompleted;break;
            default: break;
          }
          break;
      default: break;
    }
  }
  return <img className={styles.icon} draggable="false" src={src} alt="quest" />
}

function Quest({props, goal_level}) {
  const { course } = useParams();
  const navigate = useNavigate();

  const { state } = useContext(AppContext)
  const { isStaff } = state

  let status = "";
  let icon = "";
  let typeName = "";

  switch (props.type){
    case 'TriviaQuest':  typeName="Cuestionario"; break;
    case 'Q&AQuest':  typeName="Cuestionario"; break;
    case 'SurveyQuest':  typeName="Encuesta"; break;
    case 'CodeQuest': typeName="Nivel"; break;
    case 'ProyectoQuest': typeName="Proyecto"; break;
    default: typeName=""; break;
  }

  if(props.visible === false) {
    status = `${styles.disabled}`;
    icon = getQuestIcon("disabled", props.type, props.level);
  } 
  else {
    if(props.completed === true) {
      status = `${styles.completed}`;
      icon = getQuestIcon("completed", props.type, props.level);
    }
    else {
      status = `${styles.inProgress}`;
      icon = getQuestIcon("inProgress", props.type, props.level);
    }
  }

  const navigateTo = () => {
    if(!props.click_feedback || isStaff === true) {
      if(props.type === "CodeQuest" || props.type === "ProyectoQuest") {
        navigate(`/cursos/${course}/misiones/${props.qid}`);
      }
      if(props.type === "Q&AQuest") {
        navigate(`/cursos/${course}/cuestionarios/${props.qid}`);
      }
      if(props.type === "SurveyQuest") {
        navigate(`/cursos/${course}/encuestas/${props.qid}`);
      }
    }
    else {
      toast.error(props.click_feedback);
    }
  }

  return (
    <div className={styles.container}>
      {props.level === goal_level && <QuestFinishLine />}
      <Tooltip title={<span style={{ fontSize: "1rem" }}>{typeName}</span>} arrow>
        <button className={`${styles.cursor} ${styles.circulo} ${status}`}
        onClick={()=>{navigateTo()}}
        >
          {icon}
        </button>
      </Tooltip>

      {props.message? <QuestBillboard message={props.message}/> : <></>}
      
    </div>
  )
}

export default Quest
import { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { AppContext } from "../appInfo";

import Layout from "../components/Layout"
import Header from "../components/Header"

import {RecursosAPI} from "../api/RecursosAPI"

import {DefaultCopyField} from '@eisberg-labs/mui-copy-field';


function PantallaApunteDownload() {
  const { course, uidx, type, rid } = useParams();
  const [status, setStatus] = useState("")
  const [enlace, setEnlace] = useState()

  const { state } = useContext(AppContext)
  const { username } = state

  useEffect( () =>{
    if(!username || !course) {
      return
    }

    if(rid) {
      setStatus("DESCARGANDO...")
      RecursosAPI.getByID(username, course, rid).then((data) => {
        if(data['is_dasabled'] == true) {
          setStatus("Este apunte no se encuentra disponible aún")
          return  
        }
        window.location.replace(data['url'])
        setStatus("Descarga en proceso, en caso de que el archivo no se descargue automaticamente utilizar el siguiente enlace:")
        setEnlace(
          <DefaultCopyField
            sx={{width:"100%"}}
            label="Enlace de descarga"
            value={data['url']} 
          />
        )
        
      }).catch( error => {
        setStatus("Falló la descarga, vuelva a intentar o contacte con los profes")

      });
    }
    else if(uidx && type) {
      setStatus("ABRIENDO ENLACE...")
      RecursosAPI.getByType(username, course, uidx, type).then((data) => {
        if(data['is_dasabled'] == true) {
          setStatus("Este enlace no se encuentra disponible aún")
          return  
        }
        if(data['url']) {
          window.location.replace(data['url'])
          setStatus("Aguarde mientras es redireccionado al enlace")
        } else {
          setStatus(`No se ha podido encontrar el enlace "${type}" para la unidad "${uidx}" del curso "${course}"`)
        }
        
      }).catch( error => {
        setStatus("Falló la redirección, vuelva a intentar o contacte con los profes")

      });
    }
    else {
      setStatus("ERROR....ENLACE INVÁLIDo")
    }
    return () => {
      //StudentAwardsAPI.abort() // cancel request
    }

  }, [username, course, rid])

  return (
      <Layout 
        Header={<Header title={"Aguarde..."}/>}
      >
        <div style=
          {{width:"100%", display:"flex", flexDirection:"column", gap:"20px"}}
        >
          <p>{status}</p>
          {enlace}
        </div>
      </Layout>
  )
}

export default PantallaApunteDownload
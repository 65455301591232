import { api } from "./client"

const controller = new AbortController();
export const StudentQuestMainChallengeAPI = {
  getAll: async function (username, course, quest_id) {
    const response = await api.request({
      url: `/student/${username}/course/${course}/quests/${quest_id}/challenges/main/`,
      method: "GET",
      signal: controller.signal
    })
    if(response) {
      return response.data
    }
  },
  solution: async function (username, course, quest_id) {
    const response = await api.request({
      url: `/student/${username}/course/${course}/quests/${quest_id}/challenges/main/solution/`,
      method: "GET",
      signal: controller.signal
    })
    if(response) {
      return response.data
    }
  },
  abort: function(){
    controller.abort()
  },
}
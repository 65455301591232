import styles from './MiniCard.module.css'



function MiniCard({sx, icon, value, text}) {


  return (
    <div className={styles.container} style={sx}>
      <div className={styles.imgContainer}>
        {icon}
      </div>
      <div className={styles.dataValue}>{value}</div>
      <div className={styles.dataText}>{text}</div>
    </div>
  )
}

export default MiniCard
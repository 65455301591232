import { useState } from 'react'

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import { red, blue, grey } from '@mui/material/colors';

import MoreVertIcon from '@mui/icons-material/MoreVert';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';



export default function RankingCard({ props, isStaff }) {
  const [open, setOpen] = useState(false);

  let bgcolor = props.ranking <= 10? red[500] : blue[500];
  bgcolor = props.puntos? bgcolor : grey[500];

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = () => {
    handleClickOpen();
  }

  return (
    <>
    <Card sx={{ width: "clamp(350px, 80%, 700px)", position:"relative", overflow:"visible" }}>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: bgcolor }} aria-label="recipe">
            {props.username[0].toUpperCase()}
          </Avatar>
        }
        action={
            isStaff === true &&
            <IconButton aria-label="settings">
              <MoreVertIcon onClick={() => handleClickOpen()} />
            </IconButton>
        }
        title={`Puesto nº${props.ranking}: puntaje ${props.puntos}`}
        subheader={
          isStaff? `${props.username} (${props.last_login})` : `${props.username}`
        }
      />
    </Card>
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
    <DialogTitle id="alert-dialog-title">
      {"Notificación"}
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
      {`¿Qué desea notificar sobre ${props.username}?`}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose}>CANCELAR</Button>
      <Button onClick={handleClick} autoFocus>CONTINUAR</Button>
    </DialogActions>
   </Dialog>
      </>
  );
}
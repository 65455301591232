import styles from './Ribbon.module.css'
import classNames from 'classnames'

function Ribbon({status, text}) {

  
  const topLeft = false;
  const topRight = true;
  const bottomLeft = false;
  const bottomRight = false;

  const containerClass = classNames({
    [styles.container]: true,
    [styles.topLeft]: topLeft,
    [styles.topRight]: topRight,
    [styles.bottomLeft]: bottomLeft,
    [styles.bottomRight]: bottomRight,
    [styles.pass]: status==="pass",
    [styles.error]: status==="error",
  });

  const textClass = classNames({
    [styles.text]: true,
    [styles.pass]: status==="pass",
    [styles.error]: status==="error",
  }); 


  return (
    <div className={containerClass}>
      <span className={textClass}>{text}</span>
    </div>
  )
}

export default Ribbon
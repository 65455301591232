import styles from './ChallengeTestCodeScript.module.css'

import DropDown from '../../DropDown'
import Ribbon from '../../Ribbon'

import TrackChangesIcon from '@mui/icons-material/TrackChanges';

function removeHTMLbracer(text) {
  text = String(text).replace('<', '');
  text = String(text).replace('>', '');
  return text;
}

function TestCard({props}) {
  const id = props.id;
  const totalSuccess = props.totalSuccess;
  const totalTrials = props.totalTrials;
  const name = props.name[0].toUpperCase() + props.name.substring(1);;
  const inputs = props.inputs;
  const trials = props.trialsCard;
  let status = ""
  if(totalTrials === "-") {
      status = "error";
  }
  else {
      status = totalSuccess === totalTrials? "pass":"fail";
  }

  return (
    <DropDown 
    title={<div className={styles.tcardTitle}><TrackChangesIcon fontSize={'large'} />Test {id} ({totalSuccess}/{totalTrials})</div>}
    status={status}
    >
      <div className={styles.tcardContainer}>
        <div className={styles.tcardName}>
          {name}
        </div>
        <div>Entradas:</div>
        <div>{inputs}</div>

        {
          trials.map((trial, index) => {
            const description = trial.description[0].toUpperCase() + trial.description.substring(1);
            const result = trial.result;
            const actual = String(removeHTMLbracer(trial.actual));
            const expected = String(removeHTMLbracer(trial.expected));

            let trial_status = "";
            if(result === "success") {
              trial_status = "pass";
            }
            else {
              trial_status = "error";
            }

            return (
              <div className={styles.trialContainer} key={index}>
                <Ribbon status={trial_status} text={trial_status} />
                <div className={styles.trialText}>
                  <div className={styles.trialName}>
                    <pre style={{overflow:"auto"}}>{description}</pre>
                  </div>
                    {result === "error"? 
                      <></>
                      :
                      <>
                        <div>Valor obtenido/resultado:</div>
                        <div>{actual}</div>
                        <div>Valor esperado:</div>
                        <div>{expected}</div>
                      </>
                    }
                </div>
              </div>
            )
          })
        }
      </div>
    </DropDown>
  )

}

function ChallengeTestCodeScript({children, }) {

  let i = 1;
  const tests = children;
  return (
    <>
      {
        tests?.map(test => {            
            const name = test["test_name"];
            const result = test["test_description"]["result"];
            const inputs = String(test["input"]);
            let trialsCard = [];
            let totalSuccess = 0;
            let totalTrials = 0;

            if(result !== "error") {
                const trials = test["test_description"]["tests"];
                 trialsCard = trials.map(trial => {
                    const message = trial["description"];
                    const expected = trial["expected"];
                    const actual = trial["actual"];
                    const passed = trial["passed"]? "success":"failure";
                    if(passed === "success") {
                        totalSuccess++;
                    }
                    totalTrials++;
                    return {
                      "description": message,
                      "result": passed,
                      "actual": actual,
                      "expected": expected
                    }
                })
            }
            else {
                const message = test["Error"];
                totalSuccess = "-";
                totalTrials = "-";
                trialsCard.push(
                  {
                    "description": message,
                    "result": result,
                    "actual": 0,
                    "expected": 0
                  }
                )
                // trialsCard.push(new TestTrialCard(message, result));
            }
            // const componentTest = new TestCard(i, totalSuccess, totalTrials, name, inputs, componentsTrial)
            // accumulator +=  componentTest.render();
            const testCard = {
              "i": i,
              "totalSuccess": totalSuccess,
              "totalTrials": totalTrials,
              "name": name,
              "inputs": inputs,
              "trialsCard": trialsCard
            }
            i++;
            return <TestCard props={testCard}  key={i}/>
            
        })
      }
    </>
  )
}

export default ChallengeTestCodeScript